import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Input, Menu, Spin, Dropdown, Button, Typography, Image, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import HeaderSearchIcon from '../../../common/images/icons/header-search-icon.png';
import { getSessionsApi, getInstancesApi } from '../../../api/ServerApi';

const HeaderSearch = (props: any) => {

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentAppId = matchPath(location.pathname, { path: '/app/:app_id', strict: true })
  const currentApp = currentAppId && props.apps[currentAppId.params.app_id]
  const searchTextRef = useRef();

  const SearchMenu = () => {
    const [options, setOptions] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = async (e) => {
      setLoading(true);
      searchTextRef.current = e.target.value;
      const SearchText = e.target.value;
      if(SearchText) {
        const apps = {
          label: t('app_manage.app'),
          key: 'app',
          type: 'group',
          children: Object.values(props.apps).filter((app: any) => {
            return (
              i18n.resolvedLanguage === 'en' ?
                app.display_name.en :
                app.display_name.zh
            ).toLowerCase().includes(
              SearchText.toLowerCase()
            )
          }).map((app: any) => ({
            label: <NavLink to={`/app/${app.id}/workbench/active`}>
                {i18n.resolvedLanguage === 'en' ? app.display_name.en : app.display_name.zh}
            </NavLink>,
            key: `app-${app.id}`
          }))
        }
        if(!currentApp) {
          searchTextRef.current === SearchText && setOptions([apps])
        } else {
          const sessions = {
            label: t('session.planning_session'),
            key: 'sessions',
            type: 'group',
            children: ((await getSessionsApi({
              app_id: currentApp.id,
              workspace_id: props.current_workspace_id,
              page: 1,
              page_size: 10,
              active: true,
              search_key_words: SearchText
            })).data || []).map((session: any) => ({
              label: <NavLink to={`/app/${currentApp.id}/session/${session.id}/view`}>
                  {session.name}
              </NavLink>,
              key: `session-${session.id}`
            }))
          }
          const instances = {
            label: t('instance.run_instance'),
            key: 'instances',
            type: 'group',
            children: ((await getInstancesApi({
              app_id: currentApp.id,
              workspace_id: props.current_workspace_id,
              page: 1,
              page_size: 10,
              active: true,
              search_key_words: SearchText
            })).data || []).map((instance: any) => ({
              label: <NavLink to={`/app/${currentApp.id}/instance/${instance.id}/view`}>
                  {instance.name}
              </NavLink>,
              key: `instance-${instance.id}`
            }))
          }
          searchTextRef.current === SearchText && setOptions([sessions, instances])
        }
      } else {
        setOptions([])
      }
      setLoading(false);
    }
    return (
      <div className='ant-dropdown-menu' style={{minWidth: "20em"}}>
        <div style={{padding: "0.5em"}}>
          <Input onChange={handleSearch} allowClear={true} placeholder={t('common.app_header_search')} />
        </div>
        <div>
          <div className='d-flex align-items-center justify-content-center' style={{minHeight: '5em'}}>
            {
              loading ? <Spin /> : (
                options.length > 0 ?
                  <Menu className='search-menu' items={options} mode="inline" /> :
                  <Typography.Text type='secondary'>{t('common.start_to_search')}</Typography.Text>
                )
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <Dropdown
      autoFocus={true}
      placement="bottomRight"
      dropdownRender={() => <SearchMenu />}
      className='vertical-hidden'
      trigger='click'
      destroyPopupOnHide={true}>
      <Button type='text' className='header-button' icon={
        <Tooltip placement="top" title={t('common.app_header_search')}>
          <Image
            src={HeaderSearchIcon}
            preview={false}
            style={{ width: 22, height: 22 }}
          />
        </Tooltip>
      }/>
    </Dropdown>
  )
}

const mapStateToProps = (store) => ({
  apps: store.account.apps,
  current_workspace_id: store.account.current_workspace?.id,
});

export default connect(mapStateToProps, {})(HeaderSearch);
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import SessionForm, { SessionFormAction } from './SessionForm';
import { PlanningSession } from '../../interfaces/planningSession';

type Props = {
    app_id: string;
    successCallback?: (data: PlanningSession) => void;
    failCallback?: () => void;
    children?: any;
}

const SessionCreateButton = (props:Props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const formRef:any = useRef();

    useEffect(() => {
        formRef?.current?.reset();
    }, [show])

    const handleCancel = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        formRef?.current?.submit();
    }

    const handleClick = () => {
        setShow(true);
    }

    const successCallback = (data: PlanningSession) => {
        setShow(false);
        formRef!.current!.reset();
        props!.successCallback && props!.successCallback(data)
    }

    const failCallback = () => {
        props!.failCallback && props!.failCallback()
    }

    const childrenWithClickEvent = React.cloneElement(props.children, { onClick: handleClick });

    return(
        <>
            <Modal
                centered
                title={t('session.action.create')}
                open={show}
                onCancel={handleCancel}
                onOk={handleSubmit}
                cancelText={t('common.cancel')}
                okText={t('common.create')}
            >
                <SessionForm
                    ref={formRef}
                    app_id={props.app_id}
                    action={SessionFormAction.Create}
                    successCallback={successCallback}
                    failCallback={failCallback}
                    hiddenSubmit={true}
                />
            </Modal>
            {childrenWithClickEvent}
        </>
    )
}

export default SessionCreateButton;
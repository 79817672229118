import React from 'react';
import JSONInput from 'react-json-editor-ajrm';
import localeEn from 'react-json-editor-ajrm/locale/en';
import localeZh from 'react-json-editor-ajrm/locale/zh-cn';
import { useTranslation } from 'react-i18next';

const JsonEditor = (props: {
  id: string;
  value?: any;
  viewOnly?: boolean
  onChange?: (json: any) => void;
  width?: string;
  height?: string;
}) => {
  const { i18n } = useTranslation();
  return (
    <JSONInput
        id={props.id}
        placeholder= {props.value || {}}
        locale= { i18n.resolvedLanguage === 'en' ? localeEn : localeZh }
        confirmGood={true}
        width={props.width || '100%'}
        height={props.height || '100%'}
        onChange={props.onChange}
    />
  )
}

export default JsonEditor;
import {Process} from "./process";
import {RunInstance} from "./runInstance";

export interface ProcessStatus {
    created_at: Date;
    finished_at: Date;
    status: string;
    error: string;
}

export const StatusEnum = new Map([
    ["Failed", "task_execution.status_enum.failed"],
    ["Succeeded", "task_execution.status_enum.succeeded"],
    ["Running", "task_execution.status_enum.running"],
    ["Pending", "task_execution.status_enum.pending"],
    ["Canceled", "task_execution.status_enum.canceled"],
    ["Error", "task_execution.status_enum.error"],
    ["", "task_execution.status_enum.pending"],
])

export const FinalStatus = new Set(["Failed", "Succeeded", "Canceled", "Error"])

export const isPending = (r:Process) =>{
    if (!r.process_job_status?.status){
        return true
    }
    return !FinalStatus.has(r.process_job_status?.status)
}

export const isRefreshNeeded = (r:RunInstance) => {
    if (!r.readiness_status?.status || (!!r.solving_status && !r.solving_status?.status)){
        return true
    }
    if (!FinalStatus.has(r.readiness_status?.status)){
        return true
    }
    return !!r.solving_status && !FinalStatus.has(r.solving_status?.status);
}

export const ProcessType = new Map([
    ["DATA_CLONE", "task_execution.process_type_enum.data_clone"],
    ["DATA_IMPORT", "task_execution.process_type_enum.data_import"],
    ["SOLVE", "task_execution.process_type_enum.solve"],
])

import moment from 'moment';
import { pinyin } from "pinyin-pro";

import {DataFrame, FormatDataFrame} from "../interfaces/dataFrame";
import {DataFrameFilters, DataFrameFilterItem} from "../interfaces/chartView";

class viewUtils {
    static decimalFormat(value: any, accuracy: int) {
      var f = parseFloat(value);
      if (isNaN(f)) {
        return value;
      }
      const accuracyNumber =  accuracy > 0 ? parseInt(
        `1${new Array(accuracy - 1).fill('0').join('')}`
      ) : 1;
      f = Math.round(value * accuracyNumber) / accuracyNumber;
      var s = f.toFixed(accuracy);
      return s;
    }

    static copyToClipboard(text: string, callback?: () => void) {
      try {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(text).then(() => {
            !!callback && callback();
          });
        } else {
          var textarea = document.createElement('textarea');
          document.body.appendChild(textarea);
          textarea.style.position = 'fixed';
          textarea.style.clip = 'rect(0 0 0 0)';
          textarea.style.top = '10px';
          textarea.value = text;
          textarea.select();
          document.execCommand('copy', true);
          document.body.removeChild(textarea);
          !!callback && callback();
        }
      } catch(e) {}
    }

    static formatDfData(df_name: string, data: DataFrame, withData: boolean) {
        let formatTableData = {}
        formatTableData.df_name = df_name;
        formatTableData.name = data?.meta?.name;
        formatTableData.fields = (data?.meta?.columns || []).map((field, index) => ({
            key: field,
            name: (data?.meta?.columns_locale || {})[field],
            type: (data?.meta?.columns_type || [])[index]
        }));
        if(withData) {
            formatTableData.data = [];
            const num_index_column = data?.meta?.num_index_column || 0;
            for(let row = 0, len = data?.meta?.num_row || 0; row < len; row++) {
                formatTableData.data.push(formatTableData.fields.reduce((ret, item, index) => {
                    if(index < num_index_column) {
                        ret[item.key] = num_index_column === 1 ?
                            data?.index[row] :
                            (data?.index[row] || [])[index];
                    } else {
                        ret[item.key] = (data?.data[row] || [])[index-num_index_column];
                    }
                    return ret;
                }, {}))
            }
        }
        return formatTableData;
    }

    static mergeDuplicates = (data: any, filters?: DataFrameFilters) => {
      const fields = Object.keys(
        (filters?.items || [])
          .filter((item) => item?.type === "DUPLICATE")
          .reduce((ret: any, item: DataFrameFilterItem) => {
            ret[item?.field || ""] = true;
            return ret;
          }, {})
      );
      let result: any = {};
      let temp: any = {};
      for (let i = 0, len = data.length; i < len; i++) {
        for (let j = 0; j < fields.length; j++) {
          if (!temp[fields[j]]) {
            temp[fields[j]] = {};
          }
          let value = data[i][fields[j]] || "null";
          if (!temp[fields[j]][value]) {
            temp[fields[j]][value] = true;
          } else {
            if (!result[fields[j]]) {
              result[fields[j]] = [];
            }
            result[fields[j]].push(value);
          }
        }
      }
      return result;
    };

    static filterDfData(data: FormatDataFrame, filters?: DataFrameFilters) {
        let filtered_data = {...data};
        const originData = data.data;
        if (filters?.items?.length > 0) {
            let res: any = [];
            const duplicatesData = this.mergeDuplicates(originData, filters);
            for (let i = 0, len = originData.length; i < len; i++) {
              let isValid = filters.type === "OR" ? false : true;
              let row = originData[i];
              try {
                for (
                  let fi = 0, flen = (filters?.items || []).length;
                  fi < flen;
                  fi++
                ) {
                  let filterItem = (filters?.items || [])[fi];
                  let checkRes = false;
                  if (filterItem.type === "=") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      // eslint-disable-next-line
                      row[filterItem?.field ?? ""] == filterItem?.value
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "!=") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      !!row[filterItem?.field ?? ""] &&
                      row[filterItem?.field ?? ""] !== filterItem?.value
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "INCLUDE") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      row[filterItem?.field ?? ""]?.includes(
                        filterItem?.value || ""
                      )
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "EXCEPT") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      !row[filterItem?.field ?? ""]?.includes(
                        filterItem?.value || ""
                      )
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "NULL") {
                    if (row[filterItem?.field ?? ""] === "" || row[filterItem?.field ?? ""] === null) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "NOT NULL") {
                    if (row[filterItem?.field ?? ""] !== "" && row[filterItem?.field ?? ""] !== null) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "DUPLICATE") {
                    if (
                      duplicatesData[filterItem?.field ?? ""]?.includes(
                        row[filterItem?.field ?? ""] || "null"
                      )
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === ">") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      row[filterItem?.field ?? ""] > filterItem?.value
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === ">=") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      row[filterItem?.field ?? ""] >= filterItem?.value
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "<") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      row[filterItem?.field ?? ""] < filterItem?.value
                    ) {
                      checkRes = true;
                    }
                  }
                  if (filterItem.type === "<=") {
                    if (
                      !!row[filterItem?.field ?? ""] &&
                      row[filterItem?.field ?? ""] <= filterItem?.value
                    ) {
                      checkRes = true;
                    }
                  }
                  if (checkRes) {
                    if (filters?.type === "OR") {
                      isValid = true;
                      break;
                    }
                  } else {
                    if (filters?.type !== "OR") {
                      isValid = false;
                      break;
                    }
                  }
                }
              } catch (e) {}
              if (isValid) {
                res.push(row);
              }
            }
            filtered_data.filtered_data = res;
        } else {
            filtered_data.filtered_data = originData;
        }
        return filtered_data;
    }

    static showDiffValue(value: any, originValue: any) {
        if(typeof value === 'number'  && typeof originValue === 'number' && value !== originValue) {
            const diff = Math.floor((value - originValue) * Math.pow(10, 10)) / Math.pow(10, 10);
            return <span style={{
                color: diff < 0 ? 'green' : 'red',
                marginLeft: "0.3em",
            }}>({diff})</span>;
        } else {
            return <></>;
        }
    }

    static showDiffRatio(value: any, originValue: any) {
        if(typeof value === 'number'  && typeof originValue === 'number' && value !== originValue) {
            const diff = Math.floor((value - originValue) * Math.pow(10, 10)) / Math.pow(10, 10);
            const diff_ratio = originValue === 0 ? 0 : Math.floor(diff * 1.0 / originValue * 100);
            return <span style={{
                color: diff < 0 ? 'green' : 'red',
                marginLeft: "0.3em",
            }}>({diff_ratio}%{
                diff_ratio > 0 ? "↑" : diff_ratio < 0 ? "↓" : ""
            })</span>;
        } else {
            return <></>;
        }
    }

    static utcToLocal(date: any, format?: string) {
        const fmt = format || 'YYYY-MM-DD HH:mm:ss'
        return date ? moment.utc(date).local().format(fmt) : '';
    }
    static humanizeDuration(timeInMillisecond:any, locale:string = 'en'){
        var duration_result:any = "";
        var result:any = "";
        const YEAR_TEXT = { zh: " 年", en: " Years"}[locale];
        const MONTH_TEXT = { zh: " 月", en: " Months"}[locale];
        const DAY_TEXT = { zh: " 日", en: " Days"}[locale];
        const HOUR_TEXT = { zh: " 小时", en: " Hours"}[locale];
        const MINUTE_TEXT = { zh: " 分", en: " Minutes"}[locale];
        const SECOND_TEXT = { zh: " 秒", en: " Seconds"}[locale];
        if (timeInMillisecond) {
            if ((result = Math.floor(timeInMillisecond / (1000 * 60 * 60 * 24 * 30 * 12))) > 0) {//year
                duration_result = result + YEAR_TEXT;
                timeInMillisecond = timeInMillisecond - result * (1000 * 60 * 60 * 24 * 30 * 12);
            }
            if ((result = Math.floor(timeInMillisecond / (1000 * 60 * 60 * 24 * 30))) > 0) {//months
                duration_result += (!!duration_result ? " " : '') + result + MONTH_TEXT;
                timeInMillisecond = timeInMillisecond - result * (1000 * 60 * 60 * 24 * 30);
            }
            if ((result = Math.floor(timeInMillisecond / (1000 * 60 * 60 * 24))) > 0) {//days
                duration_result += (!!duration_result ? " " : '') + result + DAY_TEXT;
                timeInMillisecond = timeInMillisecond - result * (1000 * 60 * 60 * 24);
            }
            if ((result = Math.floor(timeInMillisecond / (1000 * 60 * 60))) > 0) {//Hours
                duration_result += (!!duration_result ? " " : '') + result + HOUR_TEXT;
                timeInMillisecond = timeInMillisecond - result * (1000 * 60 * 60);
            }
            if ((result = Math.floor(timeInMillisecond / (1000 * 60))) > 0) {//minute
                duration_result += (!!duration_result ? " " : '') + result + MINUTE_TEXT;
                timeInMillisecond = timeInMillisecond - result * (1000 * 60);
            }
            if ((result = Math.floor(timeInMillisecond / 1000)) > 0) {//second
                duration_result += (!!duration_result ? " " : '') + result + SECOND_TEXT;
                timeInMillisecond = timeInMillisecond - result * 1000;
            }
        }
        return duration_result;
    }

    static durationBetween(start: any, end?: any, locale:string = 'en') {
        if (!start) {
            return ''
        }
        if (!!end) {
            return viewUtils.humanizeDuration(moment.duration(moment.utc(end).diff(moment.utc(start),'milliseconds'),'milliseconds').asMilliseconds(), locale);
        } else {
            return viewUtils.humanizeDuration(moment.duration(moment.utc().diff(moment.utc(start),'milliseconds'),'milliseconds').asMilliseconds(), locale);
        }
    }

    static isExpried(date: any) {
        return moment.utc(date).isAfter(moment());
    }

    static prettifyUsername(name?: string) {
        if (!!name && /^[\u4E00-\u9FA5]+$/g.test(name.replace(" ", ""))) {
          return name.split(" ")?.reverse()?.join("") || "";
        } else {
          return name || "";
        }
    }

    static autoFormatIdentifier(string: string) {
      return pinyin(string, {
        toneType: "none",
        type: "array",
      })
        .join("")
        .replaceAll(/ /gi, "_")
        .toLowerCase()
        .replaceAll(/[^a-z_$0-9]/gi, "")
        .replace(/^[^a-z]+/i, "");
    }

    static prettifyDatetime(date: any) {
      const fmt = "YYYY-MM-DD HH:mm:ss";
      return moment(date).local().format(fmt);
    }

    static isPermanentExpried(date: any) {
      return date ? moment.utc(date).isAfter(moment().add(50, 'years')) : false;
    }
}

export default viewUtils;

const MenuWorkbenchIcon = (props) => {
  return (
    <span role="img" aria-label="dashboard" className="anticon anticon-dashboard ant-menu-item-icon" {...props}>
      <svg viewBox="0 0 21 21"focusable="false" data-icon="dashboard" width="1em" height="1em" fill="currentColor" aria-hidden="true">
        <path d="M15.7573271,2.53332715 C16.6527764,2.53332715 17.3786729,3.26054102 17.3786729,4.15760449 L17.3786729,12.6343135 C17.3786729,13.5313584 16.6527764,14.2585908 15.7573271,14.2585908 L10.0573271,14.2585537 L10.0573271,15.4006504 L14.6426729,15.4006504 L14.6426729,16.5173271 L4.35732715,16.5173271 L4.35732715,15.4006504 L8.94267285,15.4006504 L8.94267285,14.2585352 L3.24267285,14.2585537 C2.34722363,14.2585537 1.62132715,13.5313398 1.62132715,12.6342764 L1.62132715,4.15760449 C1.62132715,3.26054102 2.34722363,2.53332715 3.24267285,2.53332715 L15.7573271,2.53332715 L15.7573271,2.53332715 Z M15.7573271,3.65002246 L3.24267285,3.65002246 C2.96284961,3.65002246 2.736,3.87728027 2.736,4.15760449 L2.736,12.6343135 C2.736,12.9146377 2.96284961,13.141877 3.24267285,13.141877 L15.7573271,13.141877 C16.0371504,13.141877 16.264,12.9146377 16.264,12.6342949 L16.264,4.15760449 C16.264,3.87728027 16.0371504,3.65002246 15.7573271,3.65002246 L15.7573271,3.65002246 Z M13.3,6.48532715 L14.0882031,7.27495898 L11.9840088,9.38291992 C11.2914736,10.0767168 10.1686738,10.0767168 9.47615723,9.38291992 L8.49782422,8.40284277 C8.24060059,8.14513672 7.82354687,8.14513672 7.56632324,8.40284277 L5.65216602,10.3204697 L4.86398145,9.53085645 L6.77813867,7.61322949 C7.47067383,6.91943262 8.59347363,6.91943262 9.28600879,7.61322949 L10.2643232,8.59330664 C10.5215469,8.85099414 10.9386006,8.85099414 11.1958242,8.59330664 L13.3,6.48532715 Z"></path>
      </svg>
    </span>
  )
}

export default MenuWorkbenchIcon;
import { NavLink } from 'react-router-dom';
import { Layout, Button, Avatar, Typography, Dropdown, Menu, Space } from 'antd';
import { UserOutlined, DownOutlined, HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../utils/AuthService';
import viewUtils from '../../../utils/viewUtils';
import Language from '../../Language';

const Header = (props: any) => {
    const { t } = useTranslation();

    const userMenu = () => (
        <Menu style={{minWidth: "14em"}}>
            <Menu.Item key={'change_language'}>
                <Language />
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key={'logout'} onClick={AuthService.logout}>
                {t('common.logout')}
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <Layout.Header theme='light' className="header-content">
                <div className='d-flex align-items-center' style={{height: '100%'}}>
                    <NavLink to='/'><span className='title'>Flow平台</span></NavLink>
                </div>
                <Space className='header-button-list'>
                    <Dropdown placement="bottomRight" dropdownRender={userMenu}>
                        <Button type='text' className='d-flex align-items-center header-button'>
                            <Avatar
                               className='d-flex justify-content-center align-items-center'
                               icon={<UserOutlined />}
                               onError={() => true}
                            />
                            <div className="d-flex align-items-center" style={{
                                maxWidth: '10em',
                                padding: '0 0.8em'
                            }}>
                              <Typography.Text ellipsis={true}>
                                {viewUtils.prettifyUsername(AuthService.fullname())}
                              </Typography.Text>
                            </div>
                            <DownOutlined style={{ color: '#909CB2', fontSize: '0.8em'}} />
                        </Button>
                    </Dropdown>
                </Space>
            </Layout.Header>
        </>
    );
};

export default Header;

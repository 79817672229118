import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";

import {Tabs} from 'antd';

import ManageLayout from '../components/layouts/ManageLayout';
import NavHyperLink from '../components/NavHyperLink';
import SessionList from './components/SessionList';
import RunInstanceList from './components/RunInstanceList';

interface LocationState {
    tab?: string
}

const PlanningSessions = (props: React.PropsWithRef<any>) => {
    const { t } = useTranslation();
    const history = useHistory()
    const location = useLocation()
    const state = location.state as LocationState;

    const handleTabChange = (key: string) => {
        history.replace({ state: { tab: key} });
    }

    return (
        <ManageLayout>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <NavHyperLink routes={[{
                    path: '#',
                    breadcrumbName: t(props.match.params.status !== 'archive' ? 'nav.workbench' : 'common.archive'),
                }]} />
            </div>
            <div className='page-layout-content-content'>
                <Tabs
                    destroyInactiveTabPane={true}
                    defaultActiveKey={state?.tab || 'all_run_instances'}
                    onChange={handleTabChange}
                    items={[
                        {
                            key: 'all_run_instances',
                            label: t('session.all_run_instances'),
                            children: <RunInstanceList
                                app_id={props.match.params.app_id}
                                active={props.match.params.status !== 'archive'}
                                search={true}
                                archive={props.match.params.status !== 'archive'}
                                instance_create={props.match.params.status !== 'archive'}
                                show_session={true}
                                unarchive={props.match.params.status === 'archive'}
                                clone={true}
                            />
                        },
                        {
                            key: 'all_planning_sessions',
                            label: t('session.all_planning_sessions'),
                            children: <SessionList
                                app_id={props.match.params.app_id}
                                active={props.match.params.status !== 'archive'}
                                search={true}
                                archive={props.match.params.status !== 'archive'}
                                unarchive={props.match.params.status === 'archive'}
                                session_create={props.match.params.status !== 'archive'}
                            />
                        }
                    ]}
                />
            </div>
        </ManageLayout>
    );
};

export default PlanningSessions;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { initServerToken } from '../api/BaseApi';
import { getMeApi, getWorkspaceAppsApi } from '../api/ServerApi';
import { initAccountInfo, setDefaultWorkspace, initWorkspaceApp } from '../store/account';
import AuthService from './AuthService';
import useNovu from './useNovu';

type AppConfig = {
  status: string;
  message?: string;
}

const useInitApp = () => {
  const dispatch = useDispatch();
  const novu = useNovu();
  const [appConfig, setAppConfig] = useState<AppConfig>({status: 'loading'});

  if (process.env.REACT_APP_MODE !== 'simple-dev' && appConfig.status === 'loading') {
    const token = AuthService.token();
    initServerToken(token);
    getMeApi().then((res) => {
      if(res.status) {
        let default_workspace_id = res.data.default_workspace_id;
        //change workspace from url parmas workspace_id
        try {
          const urlParams = new URLSearchParams(window.location.search);
          const url_workspace_id = urlParams?.get('workspace_id');
          if(
            !!url_workspace_id &&
            default_workspace_id !== url_workspace_id &&
            !!res.data?.workspace_links?.find(w => w.workspace_id === url_workspace_id)
          ) {
            default_workspace_id = url_workspace_id;
          }
        }catch(e){}
        dispatch(initAccountInfo({
          account_info: {
            is_superuser: res.data.is_superuser,
            active: res.data.active,
            enterprise_mode: res.data.enterprise_mode,
            notification_info: res.data.notification_info,
            with_special_permission: res.data.with_special_permission,
          },
          token: token,
          workspaces: (res.data.workspace_links || []).map((item:any) => ({
            ...item.workspace,
            member_type: item.member_type
          })),
          default_workspace_id: default_workspace_id
        }))
        if(!default_workspace_id && (res.data.workspace_links || []).length > 0) {
          dispatch(setDefaultWorkspace({
            id: res.data.workspace_links[0].workspace?.id,
          }))
        }
        if(default_workspace_id || (res.data.workspace_links || []).length > 0) {
          getWorkspaceAppsApi({
            workspace_id: default_workspace_id || res.data.workspace_links[0].workspace?.id
          }).then((res) => {
            if(res.status) {
              dispatch(initWorkspaceApp(
                (res.data || []).map(item => ({
                  link_active: true,
                  ...item,
                }))
              ))
              setAppConfig({ status: 'logined' });
            } else {
              setAppConfig({status: 'error', message: 'Something is Error'})
            }
          });
        } else {
          setAppConfig({ status: 'logined' });
        }
        novu.init(res.data);
      } else {
        setAppConfig({status: 'error', message: 'Something is Error'})
      }
    })
  }

  return appConfig;
};

export default useInitApp;

import { Button, Checkbox, Empty, Form, Input, Modal, Pagination, Space, Table, Tooltip, notification, DatePicker, Select } from "antd";
import { useEffect, useState } from "react";
import { getAllWorkspace, getSystemUserListApi, updateUserApi } from "../../api/ServerApi";
import { User } from "../../interfaces/user";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import NavHyperLink from "../../components/NavHyperLink";

const { RangePicker } = DatePicker;

const UserList = () => {
    const { t } = useTranslation();
    const [criteria, setCriteria] = useState({});

    const handleSearch = (data: any) => {
        setCriteria(data)
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <NavHyperLink routes={[{
                    path: '#',
                    breadcrumbName: t('system_manage.nav.user_list'),
                }]} />
            </div>
            <Search onSubmit={handleSearch}/>
            <UserTable criteria={criteria}/>
        </>
    );
};

const Search = (props: {
    onSubmit: (data: any) => void
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const isSuperuserOption = [
        {
            value: null,
            label: t("common.all"),
        },
        {
            value: true,
            label: t("common.true"),
        },
        {
            value: false,
            label: t("common.false"),
        }
    ]
    const isActiveOption = [
        {
            value: null,
            label: t("common.all"),
        },
        {
            value: true,
            label: t("system_manage.user_list.active_true"),
        },
        {
            value: false,
            label: t("system_manage.user_list.active_false"),
        }
    ]
    const [workspaceOptions, setWorkspaceOptions] = useState<any[]>();

    useEffect(() => {
        getAllWorkspace().then((res) => {
            if(res.status) {
                setWorkspaceOptions([
                    {
                        value: null,
                        label: t("common.all"),
                    },
                    ...(res.data || []).map(item => {
                        return {value: item.id, label: item.name}
                    })
                ])
            }
        });
    }, []);

    const handleSubmit = () => {
        props.onSubmit(form.getFieldsValue());
    }

    const handleReset = () => {
        form.resetFields();
        props.onSubmit(form.getFieldsValue());
    }

    return (
        <Form
            style={{
                display: 'grid', 
                gridTemplateColumns: 'repeat(3,1fr)',
                gridAutoRows: 'auto',
                gap: '1em'
            }}
            form={form}
            colon={false}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="inline"
            initialValues={{
                is_superuser: null,
                active: null,
                workspace_id: null,
            }}>
            <Form.Item
                name="account"
                label={t("system_manage.user_list.account")}>
                    <Input />
            </Form.Item>
            <Form.Item
                name="is_superuser"
                label={t("system_manage.user_list.is_superuser")}>
                    <Select options={isSuperuserOption}/>
            </Form.Item>
            <Form.Item
                name="active"
                label={t("system_manage.user_list.active")}>
                    <Select options={isActiveOption}/>
            </Form.Item>
            <Form.Item
                name="workspace_id"
                label={t("system_manage.user_list.workspace")}>
                    <Select options={workspaceOptions}/>
            </Form.Item>
            <Form.Item
                name="created_at"
                label={t("system_manage.user_list.created_at")}>
                    <RangePicker style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item style={{
                gridColumn: '1 / -1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Space>
                    <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                        {t("common.query")}
                    </Button>
                    <Button htmlType="reset" onClick={handleReset}>
                        {t("common.reset")}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

const UserTable = (props: {
    criteria: any
}) => {
    const { t } = useTranslation();
    const [datum, setDatum] = useState([]);
    const [total, setTotal] = useState();
    const [pagination, setPagination] = useState({page: 1, page_size: 10});

    useEffect(() => {
        getSystemUserList(pagination, props.criteria);
    }, [pagination]);

    useEffect(()=> {
        setPagination({page: 1, page_size: pagination.page_size});
    }, [props.criteria]);

    const handlePageChange = (page: number, page_size: number) => {
        setPagination(data => ({ ...data, page: page, page_size: page_size }));
    };

    const handleEdited = () => {
        getSystemUserList(pagination, props.criteria);
    }

    const getSystemUserList = (pagination: any, criteria: any) => {
        let fromDate = null
        let toDate = null
        if(criteria.created_at && 
            criteria.created_at.length > 0) {
                fromDate = new Date(Date.parse(criteria.created_at[0]))
                fromDate.setHours(0,0,0,0)
            }
        if(criteria.created_at && 
            criteria.created_at.length > 1) {
                toDate = new Date(Date.parse(criteria.created_at[1]))
                toDate.setHours(0,0,0,0)
            }

        getSystemUserListApi({
            page: pagination.page,
            page_size: pagination.page_size,
            created_at_from: fromDate?.getTime(),
            created_at_to: toDate?.getTime(),
            ...criteria,
        }).then((res) => {
            if(res.status) {
                setDatum(res.data || []);
                setTotal(res?.pagination?.total || 0)
            } else {
                setDatum([]);
                setTotal(0);
            }
        });
    }

    const columns: ColumnsType<User> = [
        {
            width: 100,
            title: t('system_manage.user_list.account'),
            key: 'account',
            dataIndex: 'account'
        },
        {
            width: 100,
            title: t('system_manage.user_list.username'),
            key: 'username',
            render: (r) => r?.username || "-",
        },
        {
            width: 150,
            title: t('system_manage.user_list.is_superuser'),
            key: 'is_superuser',
            render: (r) => r.is_superuser ? '✅' : '❌'
        },
        {
            width: 100,
            title: t('system_manage.user_list.active'),
            key: 'active',
            render: (r) => r.active ? t('system_manage.user_list.active_true') : t('system_manage.user_list.active_false')
        },
        {
            width: 100,
            title: t('system_manage.user_list.workspace'),
            key: 'workspace',
            render: (r) => <Tooltip title={r?.workspace_name}>{r?.workspace_name || "-"}</Tooltip>
        },
        {
            width: 100,
            title: t('system_manage.user_list.created_by'),
            key: 'created_by',
            render: (r) => r.member_type === "OWNER" ? "-": r?.created_by || "-",
        },
        {
            width: 200,
            title: t('system_manage.user_list.last_login'),
            key: 'last_login',
            render: (r) => viewUtils.utcToLocal(r.last_login)
        },
        {
            width: 200,
            title: t('system_manage.user_list.created_at'),
            key: 'created_at',
            render: (r) => viewUtils.utcToLocal(r.created_at)
        },
        {
            width: 100,
            title: t('common.actions'),
            key: 'actions',
            fixed: 'right',
            align: "center",
            render: (r) => <div className="btn-group mr-2">
                <EditButton user={r} onEdited={handleEdited}/>
            </div>
        }
    ];

    if(datum && datum.length > 0) {
        return (
            <div className="table-responsive bg-white" style={{marginTop: '1em'}}>
            <Table
                pagination={false}
                rowKey={r => r.id + r.workspace_id}
                columns={columns}
                dataSource={datum}
                scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
            />
            <Pagination
                showQuickJumper
                showTotal={(total) => t("common.total", { count: total })}
                current={pagination.page}
                pageSize={pagination.page_size}
                total={total}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={[10, 20, 50]}
                style={{textAlign: 'right', justifyContent: 'flex-end', margin: '1em'}}
            />
        </div>
        )
    } else {
        return (
            <div className='bg-white' style={{padding: '10%'}}>
                <Empty/>
            </div>
        )
    }
}

const EditButton = (props: {
    user: User,
    onEdited: () => void,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);

    const handleClickEdit = () => {
        const user = props.user
        form.resetFields();
        form.setFieldsValue({
            id: user.id,
            account: user.account,
            is_superuser: user.is_superuser,
            active: user.active
        });
        setOpen(true);
    }

    const handleOk = async() => {
        const userData = form.getFieldsValue(true)
        if(!!userData.id){
            const response = await updateUserApi({
                user_id: userData.id,
                active: userData.active,
                is_superuser: userData.is_superuser
            });
            if(response.status && !!response.data.id) {
                notification['success']({
                    message: t('system_manage.user_list.tip.update', {status: t('common.success')})
                })
                props.onEdited()
                setOpen(false);
            } else {
                notification['error']({
                    message: t('system_manage.user_list.tip.update', {status: t('common.fail')})
                })
            }
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={handleClickEdit}>
                {t('common.edit')}
            </Button>
            <Modal
                title={`${t('common.edit')}${t('system_manage.user_list.user')}`}
                okText={t('common.save')}
                cancelText={t('common.cancel')}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label={t('system_manage.user_list.account')}
                        name="account"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label={t('system_manage.user_list.is_superuser')}
                        name="is_superuser"
                        valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        label={t('system_manage.user_list.active')}
                        name="active"
                        valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default UserList;
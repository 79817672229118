import axios from 'axios';
import Qs from 'qs';
import GlobalLoader from "../components/GlobalLoading";
import { getConfig } from '../config/config';
const config = getConfig();

export interface responseData {
  status: boolean;
  data: any;
  pagination?: any;
  message?: any;
  code?: any;
}

const initApi = (baseurl, token) => {
  const API = axios.create({
    baseURL: baseurl,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(token ? {'Authorization': `Bearer ${token}`} : null)
    },
    paramsSerializer: {
      serialize: params => Qs.stringify(params, { indices: false }),
    }
    // timeout: 10000,
  });

  API.interceptors.request.use(
    (config) => {
      if (config.headers.needLoading) {
        GlobalLoader.show();
        delete config.headers.needLoading;
      }
      return config;
    },
    (error) => {
      GlobalLoader.hide();
      return Promise.reject(error);
    }
  );

  API.interceptors.response.use(
    (res) => {
      GlobalLoader.hide();
      return {
        status: true,
        data: res.data,
        pagination: JSON.parse(res.headers['x-pagination'] || '{}')
      }
    },
    (error) => {
      GlobalLoader.hide();
      const status_code = error.response ? error.response.status : 500
      if(status_code === 418) {
        window.href = window.location.href
      }
      return ({
        code: status_code,
        status: false,
        message: error.response ? (
          error.response.data ? (
            error.response.data.message || error.response.data.status
          ) : error.response
        ) : 'Network Error'
      })
    }
  )
  return API;
}

const SERVER_API = initApi(`${config.api_baseurl}${config.server_api_url}`)
const initServerToken = (token) => {
  SERVER_API.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export { SERVER_API, initApi, initServerToken }

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';
import { getConfig } from "../config/config";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    forceRefresh,
    setCanUseFlowAssistant,
    closeFlowAssistantModal
} from '../store/account';
import { getWorkspaceApiToken } from '../api/ServerApi';
const config = getConfig();

type Props = {
    current_workspace_id?: string;
    current_app_id?: string;
    show_flow_assistant_modal: boolean;
    forceRefresh: () => void,
    setCanUseFlowAssistant: () => void,
    closeFlowAssistantModal: () => void,
}
const LLmAssistant = (props: Props) => {
    const { t } = useTranslation();
    const iframeRef:any = useRef();
    const [fullScreen, setFullScreen] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleCancel = () => {
        props.closeFlowAssistantModal();
        setFullScreen(false);
        props.forceRefresh();
    }

    useEffect(() => {
        if(
            !!config.llm_url &&
            !config.disable_llm &&
            iframeRef.current &&
            !!props.current_app_id &&
            !!props.current_workspace_id
        ) {
            setLoading(true);
            getWorkspaceApiToken({
                workspace_id: props.current_workspace_id
            }).then((res) => {
                if(res.status) {
                    const params = new URLSearchParams();
                    params.set('FLOW_WORKSPACE_ID', props.current_workspace_id);
                    params.set('FLOW_APP_ID', props.current_app_id);
                    params.set('FLOW_API_TOKEN', res.data);
                    iframeRef.current.src = `${config.llm_url}/env?${params.toString()}`;
                    props.setCanUseFlowAssistant(true)
                } else {
                    props.setCanUseFlowAssistant(false)
                }
                setLoading(false);
            })
        } else {
            props.setCanUseFlowAssistant(false);
        }
    // eslint-disable-next-line
    }, [
        props.current_app_id,
        props.current_workspace_id,
        props.setCanUseFlowAssistant
    ])

    const handleTriggerFullScreen = () => setFullScreen(!fullScreen);

    return(
        <div style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            pointerEvents: "none",
            display: props.show_flow_assistant_modal ? 'block' : 'none',
            userSelect: 'none'
        }}>
            <Draggable
                disabled={fullScreen ? true : false}
                position={fullScreen ? {x: 0, y: 0} : null}
                bounds='parent'
            >
                <div
                    id='flow-assistant'
                    className='ant-modal'
                    style={{
                        position: 'absolute',
                        pointerEvents: 'auto',
                        boxShadow: '1px 1px 10px 3px rgba(0,0,0,0.1)',
                        display: "flex",
                        flexDirection: "column",
                        background: 'white',
                        top: fullScreen ? 0 : '63px',
                        right: 0,
                        width: fullScreen ? "100%" : "580px",
                        maxWidth: '100%',
                        minHeight: fullScreen ? '100%' : "600px",
                        maxHeight: fullScreen ? '100%' : "calc(100% - 63px)",
                        height: fullScreen ? '100%' : '65%',
                        cursor: "move",
                    }}>
                    <div className='ant-modal-header'
                        onMouseOut={() => {
                            var fireOnThis = document.getElementById('flow-assistant');
                            if( document.createEvent )
                            {
                                var evObj = document.createEvent('MouseEvents');
                                evObj.initEvent( 'mouseup', true, false );
                                fireOnThis.dispatchEvent(evObj);
                            } else if( document.createEventObject ) {
                                fireOnThis.fireEvent('onmouseup');
                            }
                        }}>
                        <div className="ant-modal-title" style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                            <span>{t('common.assistant')}</span>
                            <Button
                                type="text"
                                icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                className='mx-2'
                                onClick={handleTriggerFullScreen}
                            />
                            <Button
                                size='large'
                                type='text'
                                style={{position: 'absolute', right: -14}}
                                onClick={handleCancel}>
                                X
                            </Button>
                        </div>
                    </div>
                    <iframe
                        title='llm'
                        ref={iframeRef}
                        style={{
                            width: '100%',
                            flex: 1,
                            border: 'none',
                            boxShadow: 'none',
                            display: !loading ? 'block' : 'none',
                        }}
                    />
                    <div style={{
                        flex: 1,
                        display: !!loading ? 'flex' : 'none',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Spin />
                    </div>
                </div>
            </Draggable>
        </div>
    )
}

const mapStateToProps = (store) => ({
    current_workspace_id: store.account.current_workspace?.id,
    current_app_id: store.account.current_app_id,
    show_flow_assistant_modal: store.account.show_flow_assistant_modal,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    forceRefresh,
    setCanUseFlowAssistant,
    closeFlowAssistantModal,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LLmAssistant);
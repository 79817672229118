import { useTranslation } from 'react-i18next';

import { Space } from 'antd';
import {
  RetweetOutlined
} from '@ant-design/icons';

const Language = ({...props}) => {
    const { t, i18n } = useTranslation()

    const changeLanguage = () => {
        i18n.changeLanguage(i18n.resolvedLanguage === 'zh' ? 'en' : 'zh')
    }

    return (
        <Space className='d-flex justify-content-between align-items-center' onClick={changeLanguage}>
            <span>{t('nav.change_language')}</span>
            <Space>
                <span style={{color: i18n.resolvedLanguage === 'zh' ? '#1890ff' : 'rgba(0, 0, 0, 0.85)'}}>
                    中
                </span>
                <RetweetOutlined style={{marginBottom: 5}} />
                <span style={{color: i18n.resolvedLanguage === 'en' ? '#1890ff' : 'rgba(0, 0, 0, 0.85)'}}>
                    En
                </span>
            </Space>
        </Space>
    )
}

export default Language;
import { useTranslation } from 'react-i18next';
import { Divider, Popover, Typography, Image, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import EditIcon from'../common/images/icons/edit-icon.png'

interface EditCellProps {
    children: any;
    suffix: any;
    object: any;
    valueField: string;
    disabled?: boolean;
    handleCellSave: (value: string) => void;
    isLink?: boolean;
    maxLength?: number;
}

const EditCell = (props: EditCellProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState();
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        if(open) {
            setValue(props.object[props.valueField]);
        }
    }, [open, props])

    return (
        <div className='d-flex align-items-center' style={{ cursor: "pointer" }} onMouseEnter={() => setShowEdit(true)} onMouseLeave={() => setShowEdit(false)}>
            <Popover
                content={
                    <div className='d-flex flex-row' style={{height: 130}}>
                        <TextArea
                            value={value}
                            showCount
                            maxLength={props.maxLength || 30}
                            style={{ width: 200, height: 120 }}
                            className="no-resize"
                            onChange={(e) => setValue(e.target.value)}
                        />
                        <div className='d-flex align-items-center' style={{height:30, marginLeft: 10}}>
                            <Button type='link' size='small' style={{padding: 0}} onClick={() => {
                                setOpen(false);
                                props.handleCellSave(value);
                            }}>{t('common.save')}</Button>
                            <Divider type="vertical"/>
                            <Button type='link' size='small' style={{padding: 0}} onClick={
                                () => setOpen(false)
                            }>{t('common.cancel')}</Button>
                        </div>
                    </div>
                }
                trigger="click"
                open={open}
                onOpenChange={(open) => {
                    if(!props.disabled && (!props.isLink || !open)) {
                        setOpen(open)
                    }
                }}
            >
                {
                    props.object[props.valueField]? (
                        <div className="d-flex flex-row align-items-center" style={{ width: '100%' }
                        }>
                            <Typography.Paragraph className='mb-0' ellipsis={{
                                rows: 2,
                                tooltip: {
                                  placement: "bottomLeft",
                                  overlayClassName: 'description_tooltip',
                                }
                            }}>
                                {props.children}
                            </Typography.Paragraph>
                            { !!props.suffix && props.suffix }
                            <Image
                                src={EditIcon}
                                preview={false}
                                style={{
                                    width: 15,
                                    height: 15,
                                    visibility: !props.disabled && showEdit?'visible':'hidden'
                                }}
                                onClick={() => {
                                    if(!props.disabled && props.isLink && !open) {
                                        setOpen(!open)
                                    }
                                }}
                            />
                        </div>
                    ) :
                    <Image
                        src={EditIcon}
                        preview={false}
                        style={{ width: 15, height: 15 }}
                        onClick={() => {
                            if(!props.disabled && props.isLink && !open) {
                                setOpen(!open)
                            }
                        }}
                    />
                }

            </Popover>
        </div>
    )
}

export default EditCell;
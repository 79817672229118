import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Pagination, Typography, Input, Modal, Table, message, Dropdown, Menu, Image } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { EllipsisOutlined } from '@ant-design/icons';

import { PlanningSession } from '../interfaces/planningSession';
import SessionCreateButton from './SessionCreateButton';
import RunInstanceCreateButton from './RunInstanceCreateButton';
import RunInstanceList from './RunInstanceList';
import viewUtils from '../../utils/viewUtils';
import { getInstancesApi, getSessionsApi, updateSessionApi } from '../../api/ServerApi';
import EditCell from '../../components/EditCell';
import GlobalLoader from '../../components/GlobalLoading';
import HeaderSearchIcon from '../../common/images/icons/header-search-icon.png';

import { getConfig } from "../../config/config";
const config = getConfig();

interface SessionListProps {
    current_workspace_id?: string;
    app_id: string;
    active: boolean;

    search?: boolean;
    archive?: boolean;
    unarchive?: boolean;
    session_create?: boolean;
    instance_create?: boolean;
};

const SessionList = (props: SessionListProps) => {
    const { t } = useTranslation();

    const [sessions, setSessions] = useState({ data: [], total: 0 });
    const [filter, setFilter] = useState({
        page: 1,
        page_size: 10,
        search_key_words: '',
        order_by: 'created_at',
        sort: 'desc'
    });
    const [selectedSessions, setSelectedSessions] = useState<React.Key[]>([]);

    const currentSession = selectedSessions.length === 1 ? sessions.data?.find(
        item => item.id === selectedSessions[0]
    ) : null;

    const handlePageChange = (page: number, page_size: number) => {
        setFilter(data => ({ ...data, page: page, page_size: page_size }));
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(
        data => ({ ...data, search_key_words: e.target.value, page: 1 })
    );

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
        if (!!sorter.order) {
            setFilter(data => ({
                ...data,
                order_by: sorter.columnKey,
                sort: sorter.order === 'ascend' ? "asc" : "desc",
                page: 1
            }))
        } else {
            setFilter(data => ({
                ...data,
                order_by: 'created_at',
                sort: 'desc',
                page: 1
            }))
        }
    };

    useEffect(() => {
        getSessionsApi({
            app_id: props.app_id,
            workspace_id: props.current_workspace_id,
            page: filter.page,
            page_size: filter.page_size,
            active: props.active,
            ...(filter.search_key_words ? { search_key_words: filter.search_key_words } : null),
            ...(filter.order_by ? { order_by: filter.order_by } : null),
            ...(filter.order_by === 'name' ? { order_by_name: filter.sort || 'desc' } : null),
            ...(filter.order_by === 'created_at' ? { order_by_created_at: filter.sort || 'desc' } : null),
            ...(filter.order_by === 'updated_at' ? { order_by_updated_at: filter.sort || 'desc' } : null),
        }).then((res) => {
            if (res.status) {
                setSessions({
                    data: res.data || [],
                    total: res?.pagination?.total || 0
                });
            }
        })
    }, [filter, props]);

    useEffect(() => {
        setFilter(data => ({
            ...data,
            page: 1,
            page_size: 10,
            search_key_words: '',
            order_by: 'created_at',
            sort: 'desc'
        }))
    }, [props]);

    const columns: ColumnsType<PlanningSession> = [
        {
            title: t('session.name'),
            key: 'name',
            width: 200,
            sorter: true,
            showSorterTooltip: false,
            render: (p) => <EditCell object={p} valueField={"name"}
                disabled={!props.active}
                handleCellSave={(value) => handleCellSave(p, value)}
                isLink={true}>
                <Typography.Paragraph className='table-link mb-0' ellipsis={{
                    rows: 1,
                    tooltip: {
                        title: p.name,
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                    }
                }}>
                    <Link to={`/app/${props.app_id}/session/${p.id}/view/${props.active ? 'active' : 'archive'}`}>
                        {p.name}
                    </Link>
                </Typography.Paragraph>
            </EditCell>
        },
        {
            title: t('session.description'),
            key: 'description',
            width: 250,
            render: (p) => <Typography.Paragraph
                type="secondary"
                ellipsis={{
                    rows: 1,
                    tooltip: {
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                    }
                }}
                className='mb-0'
            >
                {p.description}
            </Typography.Paragraph>
        },
        {
            title: t('session.created_at'),
            key: 'created_at',
            width: 150,
            sorter: true,
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            render: (p) => <span className='table-text-nowrap'>
                {viewUtils.utcToLocal(p.created_at)}
            </span>
        }
    ];

    const handleBatchArchive = async () => {
        if (selectedSessions.length > 0) {
            Modal.confirm({
                title: t('session.confirm.archive'),
                async onOk() {
                    let [successCount, failCount] = [0, 0];
                    for (let item_index in selectedSessions) {
                        const archive_response = await updateSessionApi({
                            session_id: selectedSessions[item_index],
                            active: false
                        })
                        !!archive_response.status ? successCount++ : failCount++;
                    }
                    if (successCount > 0) {
                        message.success(
                            t('session.tip.archive', {
                                count: successCount,
                                status: t('common.success')
                            })
                        );
                    }
                    if (failCount > 0) {
                        message.error(
                            t('session.tip.archive', {
                                count: failCount,
                                status: t('common.fail')
                            })
                        );
                    }
                    setSelectedSessions([]);
                    setFilter(data => ({ ...data }));
                }
            });
        }
    }

    const handleBatchUnArchive = async () => {
        if (selectedSessions.length > 0) {
            Modal.confirm({
                title: t('session.confirm.unarchive'),
                async onOk() {
                    let [successCount, failCount] = [0, 0];
                    for (let item_index in selectedSessions) {
                        const archive_response = await updateSessionApi({
                            session_id: selectedSessions[item_index],
                            active: true
                        })
                        !!archive_response.status ? successCount++ : failCount++;
                    }
                    if (successCount > 0) {
                        message.success(
                            t('session.tip.unarchive', {
                                count: successCount,
                                status: t('common.success')
                            })
                        );
                    }
                    if (failCount > 0) {
                        message.error(
                            t('session.tip.unarchive', {
                                count: failCount,
                                status: t('common.fail')
                            })
                        );
                    }
                    setSelectedSessions([]);
                    setFilter(data => ({ ...data }));
                }
            });
        }
    }

    const handleCellSave = (planningSession, value) => {
        updateSessionApi({
            session_id: planningSession.id,
            name: value
        }).then((res) => {
            if (res.status) {
                message.success(
                    t('common.update_tip', { status: t('common.success') }),
                )
                setFilter({ ...filter });
            } else {
                message.error(
                    t('common.update_tip', { status: t('common.fail') }),
                )
            }
        })
    }

    const handleDelete = async (session: PlanningSession) => {
        const res = await getInstancesApi({
            app_id: props.app_id,
            workspace_id: props.current_workspace_id,
            page: 1,
            page_size: 1,
            active: props.active,
            session_id: session.id
        });

        if (res.status) {
            if (res.data.length > 0) {
                message.error( t("session.tip.contains_instance") );
                return;
            } else {
                Modal.confirm({
                    title: t('session.confirm.delete'),
                    async onOk() {
                        const response = await updateSessionApi({
                            session_id: session.id,
                            is_deleted: true,
                        })
                        if (response.status) {
                            message.success(
                                t('session.tip.delete_session', {
                                    status: t('common.success')
                                })
                            );
                            setFilter({ ...filter });
                        } else {
                            if (response.message?.includes("you cannot delete the default session")) {
                                message.error( t("session.tip.delete_default") );
                            } else {
                                message.error(
                                    t('session.tip.delete_session', {
                                        status: t('common.fail')
                                    })
                                );
                            }
                        }
                    }
                });
            }
        }
    }

    const handleCopyId = () => {
        if (selectedSessions.length === 1) {
            viewUtils.copyToClipboard(
                selectedSessions[0],
                () => {
                    message.success(
                        t('session.tip.copy_session_id', { status: t('common.success') })
                    )
                }
            );
        }
    }

    const actionMenu = () => (
        <Menu style={{ minWidth: '6em', textAlign: 'center' }}>
            {
                !config.IsSimpleLayout && props.archive && <Menu.Item
                    key='archive'
                    disabled={selectedSessions.length < 1}
                    onClick={handleBatchArchive}>
                    {t('session.action.archive')}
                </Menu.Item>
            }
            {
                props.unarchive && <Menu.Item
                    key='unarchive'
                    disabled={selectedSessions.length < 1}
                    onClick={handleBatchUnArchive}>
                    {t('session.action.unarchive')}
                </Menu.Item>
            }
            <Menu.Item
                key='delete'
                disabled={selectedSessions.length !== 1}
                onClick={() => handleDelete(currentSession)}>
                {t('session.action.delete')}
            </Menu.Item>
            <Menu.Item
                key='copyId'
                disabled={selectedSessions?.length !== 1}
                onClick={handleCopyId}>
                {t('instance.action.copy_instance_id')}
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-2 mb-3">
                {
                    props.search ? <Input
                        className='list-search-input'
                        placeholder={t('common.keyword_search_planning_sessions')}
                        onChange={handleChange}
                        value={filter?.search_key_words}
                        suffix={
                            <Image
                                src={HeaderSearchIcon}
                                preview={false}
                                style={{ width: 15, height: 15 }}
                            />
                        }
                    /> : <div></div>
                }
                <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center">
                    {
                        props.session_create && <SessionCreateButton
                            app_id={props.app_id}
                            successCallback={() => setFilter(data => ({ ...data }))}
                        >
                            <button
                                className="btn btn-primary"
                                style={{ marginLeft: '0.5em' }}>
                                + {t('session.action.create')}
                            </button>
                        </SessionCreateButton>
                    }
                {
                    props.instance_create && <RunInstanceCreateButton
                        app_id={props.app_id}
                        successCallback={() => setFilter(data => ({ ...data }))}
                    />
                }
                <Dropdown placement="bottom" dropdownRender={actionMenu}>
                    <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{ marginLeft: '0.5em', paddingLeft: 9, paddingRight: 9 }}>
                        <EllipsisOutlined style={{ fontSize: '1.4em' }} />
                    </button>
                </Dropdown>
            </div>
        </div>
            <div className="table-responsive bg-white">
                <Table
                    tableLayout='fixed'
                    rowSelection={{
                        selectedRowKeys: selectedSessions,
                        onChange: selectedRowKeys => {
                            setSelectedSessions(selectedRowKeys)
                        },
                        columnWidth: 50,
                        fixed: true
                    }}
                    scroll={{ x: 600, scrollToFirstRowOnChange: true }}
                    pagination={false}
                    rowKey={p => p.id}
                    columns={columns}
                    expandable={{
                        expandedRowRender: p => <RunInstanceList
                            app_id={props.app_id}
                            session_id={p.id}
                            page_size={5}
                            active={props.active}
                            smallSize={true}
                            show_list_actions={true}
                        />,
                    }}
                    onChange={handleTableChange}
                    dataSource={sessions.data}
                />
                <Pagination
                    showQuickJumper
                    hideOnSinglePage={!sessions.data?.length}
                    showTotal={(total) => t("common.total", { count: total })}
                    current={filter.page}
                    pageSize={filter.page_size}
                    total={sessions.total}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50]}
                    style={{ textAlign: 'right', justifyContent: 'flex-end', margin: '1em 0' }}
                />
            </div>
        </>
    )
};

const mapStateToProps = (store) => ({
    current_workspace_id: store.account.current_workspace?.id,
})

export default connect(mapStateToProps, {})(SessionList);

import React from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import MenuArchiveIcon from '../../../common/images/icons/MenuArchiveIcon';
import MenuWorkbenchIcon from '../../../common/images/icons/MenuWorkbenchIcon';
import MenuExecutionsIcon from '../../../common/images/icons/MenuExecutionsIcon';
import NotificationIcon from '../../../common/images/icons/NotificationIcon';
// import MenuHelpIcon from '../../../common/images/icons/MenuHelpIcon';

const NavMenu = (props: { apps: any }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const currentApp = matchPath(location.pathname, {
        path: '/app/:app_id',
        strict: true
    })

    const MenuList = [
        {
            label: <NavLink to={`/app/${currentApp?.params?.app_id}/workbench/active`}>
                { t('nav.workbench') }
            </NavLink>,
            key: `workbench`,
            icon: <MenuWorkbenchIcon style={{ fontSize: '1.3em' }} />
        },
        {
            label: <NavLink to={`/app/${currentApp?.params?.app_id}/executions`}>
                { t('nav.executions') }
            </NavLink>,
            key: `executions`,
            selected: location.pathname.includes('executions'),
            icon: <MenuExecutionsIcon style={{ fontSize: '1.3em' }}  />
        },
        {
            label: <NavLink to={`/app/${currentApp?.params?.app_id}/workbench/archive`}>
                { t('nav.archive') }
            </NavLink>,
            key: `archive`,
            selected: location.pathname.includes('archive'),
            icon: <MenuArchiveIcon style={{ fontSize: '1.3em' }} />
        },
        {
            label: <NavLink to={`/app/${currentApp?.params?.app_id}/notification`}>
                { t('nav.notification') }
            </NavLink>,
            key: `notification`,
            selected: location.pathname.includes('notification'),
            icon: <NotificationIcon style={{ fontSize: '1.3em' }} />
        },
    ]

    const defaultActiveKey = (MenuList.filter((item: any) => item.selected)[0] || {}).key || 'workbench';

    return (
        <Menu
            theme="light"
            mode="inline"
            className='sidebar-menu'
            items={MenuList}
            defaultSelectedKeys={[defaultActiveKey]}
        >
        </Menu>
    );
};

const mapStateToProps = (store) => ({
  apps: store.account.apps,
})

export default connect(mapStateToProps, {})(NavMenu);

import Keycloak from 'keycloak-js';
import { getConfig } from '../config/config';
const config = getConfig();

const _kc = new Keycloak({
  url: config.auth_url,
  realm: config.auth_realm,
  clientId: config.auth_client_id
})

const initKeycloak = (onAuthenticatedCallback: () => void, onUnAuthenticatedCallback: () => void) => {
  _kc
    .init({
      // onLoad: 'login-required',
      redirectUri: window.location.href,
    })
    .then((auth) => {
      if (auth) {
        onAuthenticatedCallback();
      } else {
        onUnAuthenticatedCallback();
      }
    });
};

const fullname = () => _kc.tokenParsed?.name;
const username = () => _kc.tokenParsed?.given_name;
const empid = () => _kc.tokenParsed?.preferred_username;
const tokenParsed = () => _kc.tokenParsed;
const token = () => _kc.token;
const hasRealmRole = _kc.hasRealmRole;
const isLoggedIn = () => !!_kc.token;
const isTokenExpired = () => _kc.isTokenExpired;
const updateToken = () => _kc.updateToken;
const login = _kc.login;
const logout = () => {
  config.IsYiliSso
    ? _kc.logout({
        redirectUri: `${config.authYiliLogoutUrl}&redirctToUrl=${window.location.origin}`,
      })
    : _kc.logout();
};
const AuthService = {
  initKeycloak,
  logout,
  hasRealmRole,
  tokenParsed,
  username,
  empid,
  isLoggedIn,
  token,
  login,
  isTokenExpired,
  updateToken,
  fullname
};
export default AuthService;
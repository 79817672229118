import { useState, useEffect } from 'react';

const useWindowSize = (props: {
  id?: string
}) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const Elem = document.querySelector(`#${props?.id}`);
    if(Elem) {
      const resizeObserver = new ResizeObserver((entries) => {
        const data = {
          width: entries[0]?.contentRect?.width,
          height: entries[0]?.contentRect?.height
        }
        if(size.width !== data.width || size.height !== data.height) {
          setSize(data)
        }
      });
      resizeObserver.observe(Elem);
      return () => {
        resizeObserver.unobserve(Elem);
      }
    } else {
      function handleResize() {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [props, size]);

  return size;
};

export default useWindowSize;
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {Pagination, message, Button, Card, Space, Dropdown, Menu, Modal, Empty} from 'antd';

import { getChartViewsApi, updateChartViewApi } from '../../api/ServerApi';
import ChartViewModal from './ChartViewModal';
import { ExpandAltOutlined, EllipsisOutlined } from '@ant-design/icons';
import ChartViewComponent from '../../components/chart';
import { RunInstance } from '../../interfaces/runInstance';

interface ChartViewListProps {
    current_workspace_id?: string;
    app_id: string;
    run_instance?: RunInstance;
    apps?: any;
};

const ChartViewList = (props: ChartViewListProps) => {
    const { t } = useTranslation();
    const [chartViews, setChartViews] = useState({data: null, total: 0});
    const [filter, setFilter] = useState({
        page: 1,
        page_size: 12,
    });
    const chartViewModalRef:any = useRef();

    useEffect(() => {
        if(!!props.run_instance?.id) {
            getChartViewsApi({
                app_id: props.app_id,
                workspace_id: props.current_workspace_id,
                run_instance_id: props.run_instance?.id,
                page: filter.page,
                page_size: filter.page_size,
            }).then((res) => {
                if(res.status) {
                    setChartViews({
                        data: res.data || [],
                        total: res?.pagination?.total || 0
                    });
                }
            })
        }
    }, [ filter, props ]);

    const handlePageChange = (page: number) => setFilter(data => ({ ...data, page: page }));

    const handleAdd = () => {
        chartViewModalRef?.current?.show({});
    }

    const handleEdit = (chartView: ChartView) => {
        chartViewModalRef?.current?.show(chartView);
    }

    const handleCopy = (chartView: ChartView) => {
        chartViewModalRef?.current?.show({
            ...chartView,
            id: null,
            name: null
        });
    }

    const handleDelete = (chartView: ChartView) => {
        Modal.confirm({
            title: t('chart_view.confirm.delete'),
            onOk() {
                updateChartViewApi({
                    id: chartView.id,
                    is_deleted: true
                }).then(res => {
                    if(res.status) {
                        message.success(
                            t('chart_view.tip.delete', {status: t('common.success')})
                        );
                        setFilter(data => ({ ...data }))
                    } else {
                        message.error(
                            t('chart_view.tip.delete', {status: t('common.fail')})
                        );
                    }
                })
            }
        });
    }

    const chartViewModal = useMemo(() => (
        <ChartViewModal
            app_id={props.app_id}
            run_instance={props.run_instance}
            ref={chartViewModalRef}
            callback={() => {
                setFilter(data => ({ ...data }))
            }}
        />
    ), [props.app_id, props.run_instance])

    return (
        <>
            {chartViewModal}
            <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center tab-action">
                <button
                    disabled={
                        props.run_instance?.readiness_status?.status !== 'Succeeded' &&
                        props.run_instance?.solving_status?.status !== 'Succeeded'
                    }
                    type="button"
                    className="btn btn-primary"
                    style={{marginLeft: '0.5em'}}
                    onClick={handleAdd}>
                    {t('chart_view.action.add')}
                </button>
            </div>

            <div className='chart-view-list'>
                {(chartViews.data || []).map((chartView: ChartView) => (
                    <div key={chartView.id} className='chart-view-item'>
                        <Card
                            size='small'
                            title={chartView.name}
                            headStyle={{ border: 'none', padding: '8px 12px'}}
                            bodyStyle={{ width: '100%', height: 'calc(100% - 48px)' }}
                            style={{ height: '100%' }}
                            extra={
                                <Space size={0}>
                                    <Button
                                        type='text'
                                        icon={<ExpandAltOutlined />}
                                        onClick={() => handleEdit(chartView)}
                                    />
                                    <Dropdown dropdownRender={
                                        () => <Menu
                                            className='chart-actions'
                                            items={[
                                              {
                                                label: t('common.edit'),
                                                key: "edit",
                                                onClick: () => handleEdit(chartView)
                                              },
                                              {
                                                label: t('common.copy'),
                                                key: "copy",
                                                onClick: () => handleCopy(chartView)
                                              },
                                              {
                                                danger: true,
                                                label: t('common.delete'),
                                                key: "delete",
                                                onClick: () => handleDelete(chartView)
                                              },
                                            ]}
                                          />
                                        } placement="bottomRight">
                                        <Button
                                            type='text'
                                            icon={<EllipsisOutlined />}
                                        />
                                    </Dropdown>
                                </Space>
                            }>
                            <ChartViewComponent
                                id={`chart-list-${chartView.id}`}
                                chart_view={chartView}
                            />
                        </Card>
                    </div>
                ))}
            </div>
            { !!chartViews.data && chartViews.data?.length < 1 && <div style={{ padding: '15vh 0'}}><Empty /></div> }
            <div className="table-responsive">
                <Pagination
                    showQuickJumper
                    hideOnSinglePage={!chartViews.data?.length}
                    showTotal={(total) => t("common.total", { count: total })}
                    current={filter.page}
                    pageSize={filter.page_size}
                    total={chartViews.total}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    style={{textAlign: 'right', justifyContent: 'flex-end', margin: '1em 0'}}
                />
            </div>
        </>
    )
};

const mapStateToProps = (store) => ({
  apps: store.account.apps,
  current_workspace_id: store.account.current_workspace?.id,
})

export default connect(mapStateToProps, {})(ChartViewList);
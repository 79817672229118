import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Link, useHistory} from "react-router-dom";
import { connect } from 'react-redux';

import {Pagination, Typography, Input, Modal, Table, message, Image} from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';

import { RunInstance } from '../../interfaces/runInstance';
import {isPending, ProcessType} from '../../interfaces/processStatus';
import {Process} from "../../interfaces/process";
import viewUtils from '../../utils/viewUtils';
import { getTasksApi, updateTaskApi, terminateTaskApi } from '../../api/ServerApi';
import EditCell from '../../components/EditCell';
import ShareInstanceModal from '../../components/ShareInstanceModal';
import ProcessStatusBtn from '../../components/ProcessStatusBtn';
import HeaderSearchIcon from '../../common/images/icons/header-search-icon.png';

interface TaskListProps {
    current_workspace_id?: string;
    app_id: string;
    session_id?: string;
    instance_id?: string;
    show_instance_info?: boolean;

    solve?: boolean;
    solveCallback?: () => void;
    search?: boolean;

    apps?: any;
    is_locked?: boolean;
    instance: RunInstance;

    addActions?: JSX.Element;
};

const TaskList = (props: TaskListProps) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [tasks, seTasks] = useState({data: [], total: 0});
    const [filter, setFilter] = useState({
        page: 1,
        page_size: 10,
        search_key_words: '',
        order_by: 'created_at',
        sort: 'desc'
    });

    // process_job_status
    const handleStatusGo = (e: any, process_status:string,
                            instance_active: boolean,
                            instance_id:string,
                            process_id:string,
                            process_type: string) => {
        e.stopPropagation();
        // here we set tab based on the process_type and process_status
        if(['DATA_IMPORT','DATA_CLONE'].includes(process_type)){
            if(process_status === 'Succeeded') {
                history.push({
                    pathname: `/app/${props.app_id}/instance/${instance_id}/view/${instance_active?'active':'archive'}`,
                    state: {tab: "input_view"}
                })
            }
            if(process_status==='Failed') {
                history.push({
                        pathname: `/app/${props.app_id}/executions/${process_id}/logs`,
                        state: {tab: "log_info"}
                    }
                )
            }
        }else if (process_type==='SOLVE'){
            if(process_status === 'Succeeded') {
                history.push({
                    pathname: `/app/${props.app_id}/instance/${instance_id}/view/${instance_active?'active':'archive'}`,
                    state: {tab: "output_view"}
                })
            }
            if(process_status==='Failed') {
                history.push({
                        pathname: `/app/${props.app_id}/executions/${process_id}/logs`,
                        state: {tab: "log_info"}
                    }
                )
            }
            if(process_status === 'Running') {
                Modal.confirm({
                    title: t('task_execution.confirm.cancel_solve'),
                    onOk() {
                        terminateTaskApi({
                            process_id: process_id,
                        }).then((res) => {
                            if(res.status) {
                                message.success(
                                    t('task_execution.tip.cancel_solve_success'),
                                );
                            } else {
                                message.error(
                                    t('task_execution.tip.cancel_solve_fail'),
                                );
                            }
                        });
                    }
                });
            }
        }
    }

    const handlePageChange = (page: number, page_size: number) => {
        setFilter(data => ({ ...data, page: page, page_size: page_size }));
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(
        data => ({ ...data, search_key_words: e.target.value, page: 1 })
    );

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
        if(!!sorter.order) {
            setFilter(data => ({
                ...data,
                order_by: sorter.columnKey,
                sort: sorter.order === 'ascend' ? "asc" : "desc",
                page: 1
            }))
        } else {
            setFilter(data => ({
                ...data,
                order_by: 'created_at',
                sort: 'desc',
                page: 1
            }))
        }
    };

    const handleCellSave = (task, value) => {
        updateTaskApi({
            task_id: task.id,
            workspace_id: props.current_workspace_id,
            remark: value
        }).then((res) => {
            if(res.status) {
                message.success( t('task_execution.tip.save_remark_success') )
                setFilter({...filter})
            } else {
                message.error( t('task_execution.tip.save_remark_fail') )
            }
        })
    }

    useEffect(() => {
        getTasksApi({
            app_id: props.app_id,
            workspace_id: props.current_workspace_id,
            page: filter.page,
            page_size: filter.page_size,
            ...( props.instance_id ? { instance_id: props.instance_id } : null ),
            ...( filter.search_key_words ? { search_key_words: filter.search_key_words } : null ),
            ...( filter.order_by ? { order_by: filter.order_by } : null ),
            ...( filter.order_by === 'run_instance_name' ? { order_by_run_instance_name: filter.sort || 'desc' } : null ),
            ...( filter.order_by === 'created_at' ? { order_by_created_at: filter.sort || 'desc' } : null ),
            ...( filter.order_by === 'process_type' ? { order_by_process_type: filter.sort || 'desc' } : null ),
        }).then((res) => {
            if(res.status) {
                seTasks({
                    data: res.data || [],
                    total: res?.pagination?.total || 0
                });
            }
        });
    }, [props, filter, props.current_workspace_id]);

    useEffect(() => {
        let intervalId:any = null;
        const shouldRefresh = (tasks.data || []).filter((r: Process) => {
            return isPending(r)
        }).length > 0
        if(shouldRefresh) {
            intervalId = setTimeout(() => setFilter(data => ({ ...data })), 1000);
        }
        return () => {
            !!intervalId && clearInterval(intervalId);
        }
    }, [tasks, setFilter]);

    const columns: ColumnsType<RunInstance> = [
        {
            title: t('task_execution.process_type'),
            key: 'process_type',
            width: 120,
            sorter: true,
            showSorterTooltip: false,
            render: (r) => <Link to={`/app/${props.app_id}/executions/${r.id}/logs`}>
                <Typography.Text className='table-link table-text-nowrap'>
                    {t(`${ProcessType.get(r.process_type)}`)}
                </Typography.Text>
            </Link>
        },
        ...(props.show_instance_info ? [
            {
                title: t('instance.name'),
                key: 'name',
                width: 180,
                render: (r) => <Typography.Paragraph ellipsis={{
                    rows: 1,
                    tooltip: {
                        title: r.run_instance.name,
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                    }
                }} className='table-link mb-0'>
                    <Link to={`/app/${props.app_id}/instance/${r.run_instance.id}/view/${r.run_instance.active ? 'active' : 'archive'}`}>
                        {r.run_instance.name}
                    </Link>
                </Typography.Paragraph>
            },
            {
                title: t('instance.description'),
                key: 'description',
                width: 180,
                render: (r) => <Typography.Paragraph
                    type="secondary"
                    ellipsis={{
                        rows: 1,
                        tooltip: {
                            placement: "bottomLeft",
                            overlayClassName: 'description_tooltip',
                        }
                    }}
                    className='mb-0'>
                    {r.run_instance.description}
                </Typography.Paragraph>
            },
        ] : []),
        {
            title: t('task_execution.created_at'),
            key: 'created_at',
            width: 150,
            sorter: true,
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            render: (r) => <span className='table-text-nowrap'>
                {viewUtils.utcToLocal(r.created_at)}
            </span>
        },
        {
            title: t('task_execution.finished_at'),
            key: 'finished_at',
            width: 150,
            render: (r) => <span className='table-text-nowrap'>
                {r.process_job_status?.finished_at? viewUtils.utcToLocal(r.process_job_status?.finished_at): '-'}
            </span>
        },
        {
            title: t('task_execution.duration'),
            key: 'duration',
            width: 100,
            render: (r) => <span className='table-text-nowrap'>
                {viewUtils.durationBetween(r.created_at, r.process_job_status?.finished_at, i18n.resolvedLanguage)}
            </span>
        },
        {
            title: t('task_execution.process_job_status'),
            key: 'process_job_status',
            width: 150,
            render: (r) => r.process_job_status ? <ProcessStatusBtn
                status={r.process_job_status}
                showDuration={false}
                clickable={
                    props.show_instance_info ||
                    ['Running','Failed'].includes(r.process_job_status?.status)
                }
                onClick={(e) => handleStatusGo(e, r.process_job_status?.status, r.run_instance.active,
                r.run_instance.id,r.id,r.process_type)}
            /> : null
        },
        {
            title: t('task_execution.remark'),
            key: 'remark',
            width: 120,
            render: (r) => <EditCell object={r} valueField={"remark"} maxLength={200}
            handleCellSave={(value) => handleCellSave(r, value)}>
                {r.remark}
            </EditCell>
        },
    ]

    return (
        <>
            <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center tab-action">
                {
                    !!props.addActions && <props.addActions callback={
                        () => setFilter(data => ({ ...data, page: 1 }))
                    } />
                }
                <ShareInstanceModal instance={props.instance}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        style={{marginLeft: '0.5em'}}>
                        {t('instance.action.share')}
                    </button>
                </ShareInstanceModal>
            </div>
            {
                props.search && <Input
                    className='mb-3 list-search-input'
                    placeholder={t('common.keyword_search_execution_records')}
                    onChange={handleChange}
                    suffix={
                        <Image
                            src={HeaderSearchIcon}
                            preview={false}
                            style={{ width: 15, height: 15 }}
                        />
                    }
                />
            }
            <Table
                tableLayout='fixed'
                pagination={false}
                rowKey={r => r.id}
                columns={columns}
                dataSource={tasks.data}
                scroll={{ x: props.show_instance_info ? 1200 : 790, scrollToFirstRowOnChange: true }}
                onChange={handleTableChange}
            />
            <Pagination
                showQuickJumper
                hideOnSinglePage={!tasks.data?.length}
                showTotal={(total) => t("common.total", { count: total })}
                current={filter.page}
                pageSize={filter.page_size}
                total={tasks.total}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={[10, 20, 50]}
                style={{textAlign: 'right', justifyContent: 'flex-end', margin: '1em 0'}}
            />
        </>
    );
};

const mapStateToProps = (store) => ({
  apps: store.account.apps,
  current_workspace_id: store.account.current_workspace?.id,
})

export default connect(mapStateToProps, {})(TaskList);

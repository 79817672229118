import React, { useRef, useEffect } from 'react';
import './ManageLayout.css';
import { withRouter, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Empty } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import NavMenu from './NavMenu';
import Header from './Header';
import CreateWorkspaceModal from '../../CreateWorkspaceModal';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { setCurrentAppId, triggerMenuStatus } from '../../../store/account';
import useCustomHistory from '../../../utils/useCustomHistory';
import { getConfig } from "../../../config/config";
const config = getConfig();

interface Props {
    children?: any;
    setCurrentAppId?: (string) => void,
};

const ManageLayout = (props: Props<any>) => {
    const { t, i18n } = useTranslation();
    const currentSize = useRef();
    useCustomHistory();

    const NoWorkspace = props.workspaces.length < 1 &&
        props.account_info?.enterprise_mode == true &&
        !props.account_info?.is_superuser;

    const currentAppId = matchPath(props.location.pathname, {
        path: '/app/:app_id',
        strict: true
    })
    const currentApp = currentAppId && props.apps[currentAppId.params.app_id]

    useEffect(() => {
        props.setCurrentAppId(currentAppId?.params?.app_id)
    // eslint-disable-next-line
    }, [currentAppId])

    const handleResize= (e) => {
        if(e.type === 'mousedown') {
            currentSize.current = { x: e.clientX, y: e.clientY }
        }
        if(e.type === 'mouseup') {
            if(currentSize.current?.x === e.clientX && currentSize.current?.y === e.clientY) {
                if(document.getElementById('help-iframe-content').clientWidth > 0) {
                    document.getElementById('resize-bar').style.width='100vw';
                } else {
                    document.getElementById('resize-bar').style.width='55vw';
                }
            }
        }
    }

    if(NoWorkspace) {
        return (
            <Layout className={`page-layout`}>
                <Layout.Content className='layout-content-padding layout-content-without-help '>
                    <Empty
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 'calc(50% - 65px)'
                        }}
                        description={t('workspace.tips.no_workspace')}
                    />
                </Layout.Content>
            </Layout>
        )
    }

    return (
        <Layout className={`page-layout ${!!config.IsSimpleLayout ? 'page-simple-layout' : ''}`}>
            { !config.IsSimpleLayout && <CreateWorkspaceModal /> }
            { !config.IsSimpleLayout && <Header/> }
            <Layout className={`page-layout-content ${!currentApp && 'd-flex align-items-center'}`}>
                {
                    currentApp && !config.IsSimpleLayout && <Layout.Sider
                        width={'220px'}
                        className='slider-horizontal'
                        collapsible
                        collapsed={props.menu_collapsed}
                        onCollapse={(value) => props.triggerMenuStatus(value)}>
                        <div className='slider-content'>
                            <div className='menu-content'>
                                <NavMenu />
                            </div>
                            {
                                !props.menu_collapsed && <div className='menu-version'>
                                    {t('common.version')}: {currentApp?.app_manifest?.version}
                                </div>
                            }
                        </div>
                    </Layout.Sider>
                }
                {
                    !!currentApp ? <Layout.Content className='layout-content-with-help'>
                        <div className="resize-box-content">
                            <div
                                id='resize-bar'
                                className="resize-bar"
                                style={{
                                    width: `calc(100vw - ${!!config.IsSimpleLayout ? 0 : props.menu_collapsed ? 80 : 220}px)`,
                                    maxWidth: `calc(100vw - ${!!config.IsSimpleLayout ? 0 : props.menu_collapsed ? 80 : 220}px)`,
                                }}
                                onMouseDown={handleResize}
                                onMouseUp={handleResize}>
                            </div>
                            <div className="dividing-line-horizontal">
                                <QuestionCircleOutlined style={{ marginBottom: 7 }} />
                                <span>{t('nav.docs')}</span>
                            </div>
                            <div className="resize-real-content layout-content-padding">
                                {props.children}
                            </div>
                        </div>
                        <div id='help-iframe-content' className="resize-box-help">
                            <iframe
                                id='help-iframe'
                                src={currentApp?.doc_url[i18n.resolvedLanguage]}
                                title={'docs'}
                            />
                        </div>
                    </Layout.Content> : <Layout.Content
                        className='layout-content-padding layout-content-without-help '>
                        {
                            !currentAppId ? props.children : <Empty
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 'calc(50% - 65px)'
                                }}
                                description={t('home.app_blank')}
                            />
                        }
                    </Layout.Content>
                }
            </Layout>
        </Layout>
    );
};

const mapStateToProps = (store) => ({
    workspaces: store.account.workspaces,
    account_info: store.account.account_info,
    apps: store.account.apps,
    menu_collapsed: store.account.menu_collapsed,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCurrentAppId,
  triggerMenuStatus
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageLayout));

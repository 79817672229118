import React from "react";
// import loading from "../assets/loading.svg";
import { Spin } from 'antd';
const Loading = () => (
  <div className="spinner">
    {/*<img src={loading} alt="Loading" />*/}
      <div style={{
          position: 'fixed',
          zIndex: 9999,
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          background: 'rgba(255,255,255,0.3)'
      }}>
          <Spin size="large" tip=""/>
      </div>
  </div>
);

export default Loading;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleDevLayout from '../../components/layouts/SimpleDevLayout';
import { RunInstance } from '../../interfaces/runInstance';
import { Link } from "react-router-dom";
import {Pagination, Typography, Table} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import viewUtils from '../../utils/viewUtils';
import NavHyperLink from '../../components/NavHyperLink';
import { getInstanceList } from '../../api/AppApi';
import { initApi } from '../../api/BaseApi';

const AppApiService = initApi(process.env.REACT_APP_API_BASE_URL);

const PlanningSessions = (props: React.PropsWithRef<any>) => {

    const { t } = useTranslation();

    const InstancesTable = () => {
        const [planningSessionsView, setPlanningSessionsView] = useState({data: [], total: 0});
        const [filter, setFilter] = useState({page: 1, page_size: 10});

        const handlePageChange = (page: number, page_size: number) => {
            setFilter(data => ({ ...data, page: page, page_size: page_size }));
        };

        useEffect(() => {
            getInstanceList(AppApiService, {
                page: filter.page,
                page_size: filter.page_size,
            }).then((res) => {
                if(res.status) {
                    setPlanningSessionsView({
                        data: res.data || [],
                        total: res?.pagination?.total || 0
                    });
                }
            });
        }, [filter]);

        const columns: ColumnsType<RunInstance> = [
            {
                title: t('Run Token'),
                key: 'run_token',
                render: (r) => <Typography.Paragraph className='table-link' ellipsis={{
                    rows: 1,
                    tooltip: {
                        title: r.run_token,
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                    }
                }}>
                    <Link to={`/run_instances/${r.run_token}/view`}>
                        {r.run_token}
                    </Link>
                </Typography.Paragraph>
            },
            {
                title: t('instance.created_at'),
                key: 'created_at',
                render: (r) => viewUtils.utcToLocal(r.created_at)
            },
            {
                title: t('instance.owner'),
                key: 'owner',
                render: (r) => r.owner
            },
        ];


        return (
            <>
               <div className={ 'table-responsive bg-white' }>
                    <Table
                        size={ 'large'}
                        pagination={false}
                        rowKey={r => r.run_token}
                        columns={columns}
                        dataSource={planningSessionsView.data}
                        rowClassName={() =>'bg-gray'}
                    />
                    <Pagination
                        size={'default'}
                        showQuickJumper
                        hideOnSinglePage={!planningSessionsView.data?.length}
                        showTotal={(total) => t("common.total", { count: total })}
                        current={filter.page}
                        pageSize={filter.page_size}
                        total={planningSessionsView.total}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={[10, 20, 50]}
                        style={{textAlign: "center", justifyContent: 'center', margin: '1em 0'}}
                    />
                </div>
            </>
        )
    }

    return (
        <SimpleDevLayout>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
                <NavHyperLink routes={[{
                    path: '#',
                    breadcrumbName: 'Instances',
                }]} />
            </div>
            <InstancesTable />
        </SimpleDevLayout>
    );
};

export default PlanningSessions;
import React, {PropsWithRef, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import {Tabs, Descriptions, Empty, List, message} from "antd";

import ManageLayout from '../components/layouts/ManageLayout';
import {Process, ProcessMainLogs} from "../interfaces/process";
import {StatusEnum, ProcessType} from '../interfaces/processStatus';
import { LogViewer, LogViewerSearch} from '@patternfly/react-log-viewer';
import NavHyperLink from '../components/NavHyperLink';
import { getTaskLogsApi } from '../api/ServerApi';
import viewUtils from '../utils/viewUtils';
import {useLocation} from "react-router-dom";

const ExecutionLogs = (props: PropsWithRef<any>) => {
    const { t, i18n } = useTranslation();
    const { state } = useLocation();

    const [processMainLogs, setProcessMainLogs] = useState({} as ProcessMainLogs);
    const [process, setProcess] = useState({} as Process);

    useEffect(() => {
        getTaskLogsApi({
            process_id: props.match.params.process_id,
        }).then((res) => {
            if(res.status) {
                setProcessMainLogs(res.data);
                setProcess(res.data.process);
            } else {
                message.error(
                    t('task_execution.tip.get_info_fail')
                );
            }
        });
    }, [props.match.params, t]);

    const ProcessDetails = () => {
        return (
            <Descriptions bordered={true} column={1}>
                <Descriptions.Item label={t('task_execution.process_id')}>
                    {props.match.params.process_id}
                </Descriptions.Item>
                <Descriptions.Item label={t('task_execution.process_type')}>
                    {t(`${ProcessType.get(process?.process_type)}`)}
                </Descriptions.Item>
                <Descriptions.Item label={t('task_execution.process_job_status')}>
                    {process? t(`${StatusEnum.get(process?.process_job_status?.status || '')}`) : null}
                </Descriptions.Item>
                <Descriptions.Item label={t('task_execution.created_at')}>
                    {viewUtils.utcToLocal(process?.created_at)}
                </Descriptions.Item>
                <Descriptions.Item label={t('task_execution.started_at')}>
                    {viewUtils.utcToLocal(process?.process_job_status?.created_at)}
                </Descriptions.Item>
                <Descriptions.Item label={t('task_execution.finished_at')}>
                    {process?.process_job_status?.finished_at? viewUtils.utcToLocal(process.process_job_status?.finished_at): '-'}
                </Descriptions.Item>
                <Descriptions.Item label={t('task_execution.duration')}>
                    {viewUtils.durationBetween(process?.created_at, process?.process_job_status?.finished_at, i18n.resolvedLanguage)}
                </Descriptions.Item>
            </Descriptions>
        )
    }

    const LogTable = () => {
        const nodeLogs = processMainLogs?.nodes || [];
        const [search, setSearch] = useState("");
        const handleSearchChange = (key: string) => {
            setSearch(key)
        }
        const formatMainLog = (log?: string) => {
            const formatLog = (log || "").replace(
                /([^|\n].*ERROR.*(\n|$))/g, `\x1b[1;4;31m$1\x1b[0m`
            )
            const ret = !!search ? formatLog.replace(new RegExp(`(${search})`,"gim"), `\x1b[43m$1\x1b[47m`) : formatLog
            return (ret || '') + new Array(3).fill("").join("\n")
        }
        return (
            <div>
                <List itemLayout={'horizontal'}
                      dataSource={nodeLogs}
                      renderItem={item => (
                          <div>
                          <div className='bg-white'>
                              <Descriptions bordered={true} column={1}>
                                    <Descriptions.Item label={t('task_execution.log.name')}>
                                        {item.displayName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t('task_execution.log.id')}>
                                        {item.name}
                                    </Descriptions.Item>
                                   <Descriptions.Item label={t('task_execution.log.status')}>
                                        {t(`${StatusEnum.get(item?.phase)}`)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t('task_execution.log.started_at')}>
                                        {viewUtils.utcToLocal(item?.startedAt)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t('task_execution.log.finished_at')}>
                                        {item?.finishedAt? viewUtils.utcToLocal(item.finishedAt): '-'}
                                    </Descriptions.Item>

                              </Descriptions>

                          </div>
                          <div className='mt-3 bg-white p-4'>
                                {
                                    !!item.main_log ? <LogViewer
                                        data={formatMainLog(item.main_log)}
                                        hasLineNumbers={true}
                                        theme={'dark'}
                                        isTextWrapped={false}
                                        toolbar={
                                            <LogViewerSearch
                                                placeholder={t('common.keyword_search')}
                                                minSearchChars={1}
                                                className='log-search-form mb-3'
                                                onChange={handleSearchChange}
                                            />
                                        }
                                    /> : <Empty description={t('common.empty')} className='my-5' />
                                }
                          </div>
                          </div>
                      )
                      }
                />
            </div>

        )
    }
    return (
        <ManageLayout>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <NavHyperLink showBack={true} routes={[{
                    path: '',
                    breadcrumbName: t('task_execution.log.title'),
                }, {
                    path: '#',
                    breadcrumbName: `${t('task_execution.log.id')}(${props.match.params.process_id})`,
                }]} />
            </div>
            <div className='page-layout-content-content'>
                <Tabs
                    destroyInactiveTabPane={true}
                    defaultActiveKey={!!state?.tab ? state?.tab : 'task_info'}
                    items={[
                        {
                            key: 'task_info',
                            label: t('task_execution.info'),
                            children: <ProcessDetails />
                        },
                        {
                            key: 'log_info',
                            label: t('task_execution.log.info'),
                            children: <LogTable />
                        }
                    ]}
                />
            </div>
        </ManageLayout>
    );
};

ExecutionLogs.propTypes = {

};

export default ExecutionLogs;

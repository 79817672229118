import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, message } from 'antd';
import RunInstanceForm, { RunInstanceFormAction } from './RunInstanceForm';
import { RunInstance } from '../../interfaces/runInstance';
import { connect } from 'react-redux';

type Props = {
    apps: any;
    app_id: string;
    session_id: string;
    successCallback?: (data: RunInstance) => void;
    failCallback?: () => void;
}

const RunInstanceCreateButton = (props:Props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const formRef:any = useRef();

    useEffect(() => {
        formRef?.current?.reset();
    }, [show])

    const handleCancel = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        formRef?.current?.submit();
    }

    const handleClick = () => {
        if(new Date().getTime() > Date.parse(props.apps[props.app_id].to_time)) {
            message.error(t("system_manage.app_permission.tip.expired"))
            return
        }
        
        setShow(true);
    }

    const successCallback = (data: RunInstance) => {
        setShow(false);
        formRef!.current!.reset();
        props!.successCallback && props!.successCallback(data)
    }

    const failCallback = () => {
        props!.failCallback && props!.failCallback()
    }

    if(!props.apps[props.app_id] ||
        (props.apps[props.app_id].app_manifest?.pipelines?.IMPORT || []).length < 1) {
        return null;
    }

    return(
        <>
            <Modal
                centered
                title={t('instance.action.create')}
                open={show}
                onCancel={handleCancel}
                onOk={handleSubmit}
                cancelText={t('common.cancel')}
                okText={t('common.create')}
            >
                <RunInstanceForm
                    ref={formRef}
                    app_id={props.app_id}
                    action={RunInstanceFormAction.Create}
                    successCallback={successCallback}
                    failCallback={failCallback}
                    helpCallback={handleCancel}
                    hiddenSubmit={true}
                    default={{
                        session_id: props.session_id
                    }}
                />
            </Modal>
            <button
                className="btn btn-primary"
                style={{marginLeft: '0.5em'}}
                onClick={handleClick}>
                + {t('instance.action.create')}
            </button>
        </>
    )
}

const mapStateToProps = (store) => ({
  apps: store.account.apps,
})

export default connect(mapStateToProps, {})(RunInstanceCreateButton);
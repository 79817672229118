import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessStatus } from '../interfaces/processStatus';
import viewUtils from '../utils/viewUtils';
import { Tag, Button } from 'antd'

const ProcessStatusBtn = (props: {
    status: ProcessStatus;
    clickable?: boolean;
    showDuration: bool;
    onClick?: (e: any) => void;
}) => {
    const { t, i18n } = useTranslation();

    const StatusTag = () => {
        if(props.status?.status === 'Failed') {
            return <Tag color="red">{t('common.fail')}</Tag>;
        } else if(props.status?.status === 'Error') {
            return <Tag color="red">{t('common.fail')}</Tag>
        } else if(props.status?.status === 'Canceled') {
            return <Tag color="red">{t('common.fail')}</Tag>;
        } else if(props.status?.status === 'Succeeded') {
            return <Tag color="green">{t('common.success')}</Tag>;
        } else if(props.status?.status === 'Terminating') {
            return <Tag color="gold">{t('common.running')}</Tag>;
        } else if(props.status?.status === 'Running') {
            return <Tag color="gold">{t('common.running')}</Tag>;
        } else {
            return <Tag color="gold">{t('common.pending')}</Tag>;
        }
    }

    return (
        <Button
            type='text'
            icon={<StatusTag />}
            className={`instance-status-button ${
                !!props.clickable || ( props.clickable === undefined && ['Succeeded','Failed'].includes(
                    props.status?.status
                )) ? 'instance-status-button-active' : ''
            } d-flex align-items-center`}
            onClick={(e)=> props.onClick && props.onClick(e)}
        >
            {
                (!['Succeeded', 'Failed', 'Error', 'Canceled'].includes(
                    props.status?.status
                ) && props.showDuration) ? viewUtils.durationBetween(
                    props.status?.created_at, null, i18n.resolvedLanguage
                ) : <></>
            }
        </Button>
    );
};

export default ProcessStatusBtn;

import {
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Space,
  Table,
  Tooltip,
  notification,
  DatePicker,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import {
  saveSystemAppPermissionApi,
  getAllWorkspace,
  getWorkspaceAppLinksApi,
  getSystemAppPermissionListApi,
} from "../../api/ServerApi";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import NavHyperLink from "../../components/NavHyperLink";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const AppPermission = () => {
  const { t } = useTranslation();
  const [criteria, setCriteria] = useState({});

  const handleSearch = (data: any) => {
    setCriteria(data);
  };

  const handleSaved = () => {
    setCriteria({ ...criteria });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <NavHyperLink
          routes={[
            {
              path: "#",
              breadcrumbName: t("system_manage.nav.app_permission"),
            },
          ]}
        />
      </div>
      <Search onSubmit={handleSearch} />
      <CreateEditButton onSaved={handleSaved} />
      <AppPermissionTable criteria={criteria} />
    </>
  );
};

const Search = (props: { onSubmit: (data: any) => void }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [workspaceOptions, setWorkspaceOptions] = useState<any[]>();

  useEffect(() => {
    getAllWorkspace().then((res) => {
      if (res.status) {
        setWorkspaceOptions([
          {
            value: null,
            label: t("common.all"),
          },
          ...(res.data || []).map((item) => {
            return { value: item.id, label: item.name };
          }),
        ]);
      }
    });
  }, []);

  const handleSubmit = () => {
    props.onSubmit(form.getFieldsValue());
  };

  const handleReset = () => {
    form.resetFields();
    props.onSubmit(form.getFieldsValue());
  };

  return (
    <Form
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gridAutoRows: "auto",
        gap: "1em",
      }}
      form={form}
      colon={false}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="inline"
      initialValues={{
        workspace_id: null,
      }}
    >
      <Form.Item name="workspace_id" label={t("system_manage.app_permission.workspace")}>
        <Select options={workspaceOptions} />
      </Form.Item>
      <Form.Item name="created_at" label={t("system_manage.app_permission.created_at")}>
        <RangePicker />
      </Form.Item>
      <Form.Item
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            {t("common.query")}
          </Button>
          <Button htmlType="reset" onClick={handleReset}>
            {t("common.reset")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const AppPermissionTable = (props: { criteria: any }) => {
  const { t, i18n } = useTranslation();
  const [datum, setDatum] = useState([]);
  const [total, setTotal] = useState();
  const [pagination, setPagination] = useState({ page: 1, page_size: 10 });

  useEffect(() => {
    getSystemAppPermissionList(pagination, props.criteria);
  }, [pagination]);

  useEffect(()=> {
    setPagination({page: 1, page_size: pagination.page_size});
  }, [props.criteria]);

  const handlePageChange = (page: number, page_size: number) => {
    setPagination((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const handleSaved = () => {
    getSystemAppPermissionList(pagination, props.criteria);
  };

  const getSystemAppPermissionList = (pagination: any, criteria: any) => {
    let fromDate = null;
    let toDate = null;
    if (criteria.created_at && criteria.created_at.length > 0) {
      fromDate = new Date(Date.parse(criteria.created_at[0]));
      fromDate.setHours(0, 0, 0, 0);
    }
    if (criteria.created_at && criteria.created_at.length > 1) {
      toDate = new Date(Date.parse(criteria.created_at[1]));
      toDate.setHours(0, 0, 0, 0);
    }

    getSystemAppPermissionListApi({
      page: pagination.page,
      page_size: pagination.page_size,
      created_at_from: fromDate?.getTime(),
      created_at_to: toDate?.getTime(),
      ...criteria,
    }).then((res) => {
      if (res.status) {
        setDatum(res.data || []);
        setTotal(res?.pagination?.total || 0);
      } else {
        setDatum([]);
        setTotal(0);
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: t("system_manage.app_permission.company"),
      key: "company",
      dataIndex: "company",
    },
    {
      title: t("system_manage.app_permission.workspace_name"),
      key: "workspace",
      render: (r) => (
        <Tooltip title={r?.workspace.name}>{r?.workspace.name}</Tooltip>
      ),
    },
    {
      title: t("system_manage.app_permission.app_name"),
      key: "app",
      render: (r) => (
        <Tooltip
          title={r?.app?.app_manifest?.display_name[i18n.resolvedLanguage]}
        >
          {r?.app?.app_manifest?.display_name[i18n.resolvedLanguage]}
        </Tooltip>
      ),
    },
    {
      title: t("system_manage.app_permission.app_from_time"),
      key: "from_time",
      render: (r) => viewUtils.utcToLocal(r.from_time, "YYYY-MM-DD"),
    },
    {
      title: t("system_manage.app_permission.app_to_time"),
      key: "to_time",
      render: (r) => viewUtils.utcToLocal(r.to_time, "YYYY-MM-DD"),
    },
    {
      title: t("system_manage.app_permission.created_at"),
      key: "created_at",
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (r) => (
        <div className="btn-group mr-2">
          <CreateEditButton permission={r} onSaved={handleSaved} />
        </div>
      ),
    },
  ];

  if (datum && datum.length > 0) {
    return (
      <div className="table-responsive bg-white" style={{ marginTop: "1em" }}>
        <Table
          pagination={false}
          rowKey={(r) => r.id}
          columns={columns}
          dataSource={datum}
          scroll={{ x: 700, scrollToFirstRowOnChange: true }}
        />
        <Pagination
          showQuickJumper
          showTotal={(total) => t("common.total", { count: total })}
          current={pagination.page}
          pageSize={pagination.page_size}
          total={total}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={[10, 20, 50]}
          style={{ textAlign: "right", justifyContent: 'flex-end', margin: "1em" }}
        />
      </div>
    );
  } else {
    return (
      <div className="bg-white" style={{ padding: "10%" }}>
        <Empty/>
      </div>
    );
  }
};

const CreateEditButton = (props: { permission?: any; onSaved: () => void }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [init, setInit] = useState(true);
  const [workspaceOptions, setWorkspaceOptions] = useState<any[]>();
  const [appOptions, setAppOptions] = useState<any[]>();
  const [datum, setDatum] = useState<any[]>([]);
  const workspaceId = Form.useWatch("workspace_id", form);

  useEffect(() => {
    if (open) {
      setInit(true);
      getAllWorkspace().then((res) => {
        if (res.status) {
          setWorkspaceOptions(
            (res.data || []).map((item) => {
              return {
                value: item.id,
                label: `${item.name}(${item.owner[0]?.account})`,
              };
            })
          );
        }
      });
      if (props.permission) {
        setDatum([props.permission]);
      } else {
        form.resetFields();
        setDatum([]);
      }
    }
  }, [open]);

  useEffect(() => {
    if (workspaceId) {
      getWorkspaceAppLinksApi({
        page: 1,
        page_size: 1,
        workspace_id: workspaceId,
        pagination: false,
      }).then((res) => {
        if (res.status) {
          setAppOptions(
            (res.data || []).map((item) => {
              return {
                value: item.id,
                label: item?.app_manifest?.display_name[i18n.resolvedLanguage],
                data: item,
              };
            })
          );
          if (init) {
            setInit(false);
          } else {
            form.setFieldValue("app_id", undefined);
            setDatum([]);
          }
        }
      });
    }
  }, [workspaceId]);

  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }

    for (const data of datum) {
      if (!data.from_time || !data.to_time) {
        notification.error({
          message: t("system_manage.app_permission.tip.no_app_use_time"),
        });
        return;
      }
    }

    const response = await saveSystemAppPermissionApi(
      datum.map((item) => {
        return {
          company: form.getFieldValue("company"),
          workspace_id: form.getFieldValue("workspace_id"),
          id: item.id,
          app_id: item.app_id,
          from_time: item.from_time,
          to_time: item.to_time,
          delete_flag: item.delete_flag,
        };
      })
    );
    if (response.status) {
      notification.success({
        message: t(
          props.permission
            ? "system_manage.app_permission.tip.save"
            : "system_manage.app_permission.tip.create",
          { status: t("common.success") }
        ),
      });
      setOpen(false);
      props.onSaved();
    } else {
      if (
        response.message?.includes(
          "Permission with this app_id and workspace_id already exists"
        )
      ) {
        notification.error({
          message: t("system_manage.app_permission.tip.app_workspace_exists"),
        });
      } else {
        notification.error({
          message: t(
            props.permission
              ? "system_manage.app_permission.tip.save"
              : "system_manage.app_permission.tip.create",
            { status: t("common.fail") }
          ),
        });
      }
    }
  };

  const handleChangeApp = (app_ids: string[], options: any[]) => {
    setDatum(
      options.map((option) => {
        return {
          app_id: option.value,
          app: option.data,
        };
      })
    );
  };

  const handleChangeUseTime = (date: any, dateString: any, permission: any) => {
    permission.from_time = new Date(Date.parse(date[0]));
    permission.to_time = new Date(Date.parse(date[1]));
    setDatum([...datum]);
  };

  const handleClickDelete = (permission: any) => {
    permission.delete_flag = true;
    setDatum([...datum]);
  };

  const columns: ColumnsType<any> = [
    {
      title: t("system_manage.app_permission.app_name"),
      key: "app_name",
      render: (r) => (
        <Tooltip
          title={r?.app?.app_manifest?.display_name[i18n.resolvedLanguage]}
        >
          {r?.app?.app_manifest?.display_name[i18n.resolvedLanguage]}
        </Tooltip>
      ),
    },
    {
      title: t("system_manage.app_permission.app_use_time"),
      key: "app_use_time",
      width: 250,
      render: (r) => (
        <RangePicker
          defaultValue={
            props.permission
              ? [
                  dayjs(viewUtils.utcToLocal(r.from_time)),
                  dayjs(viewUtils.utcToLocal(r.to_time)),
                ]
              : [null, null]
          }
          onChange={(date, dateString) =>
            handleChangeUseTime(date, dateString, r)
          }
        />
      ),
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (r) => (
        <div className="btn-group mr-2">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => handleClickDelete(r)}
          >
            {t("common.delete")}
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Button type="primary" onClick={() => setOpen(true)}>
          {props.permission ? t("common.edit") : t("common.new")}
        </Button>
      </div>
      <Modal
        title={
          props.permission
            ? `${t("common.edit")}${t("system_manage.app_permission.title")}`
            : `${t("common.new")}${t("system_manage.app_permission.title")}`
        }
        okText={props.permission ? t("common.save") : t("common.new")}
        cancelText={t("common.cancel")}
        open={open}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          initialValues={{
            company: props.permission?.company,
            workspace_id: props.permission?.workspace.id,
            app_id: props.permission ? [props.permission.app.id] : [],
          }}
        >
          <Form.Item
            label={t("system_manage.app_permission.company")}
            name="company"
            rules={[{ required: true, message: "" }]}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            label={t("system_manage.app_permission.workspace")}
            name="workspace_id"
            rules={[{ required: true, message: "" }]}
          >
            <Select disabled={props.permission} options={workspaceOptions} />
          </Form.Item>
          <Form.Item
            label={t("system_manage.app_permission.app_name")}
            name="app_id"
            rules={[{ required: true, message: "" }]}
          >
            <Select
              mode="multiple"
              disabled={props.permission}
              options={appOptions}
              onChange={handleChangeApp}
            />
          </Form.Item>
        </Form>
        <Table
          pagination={false}
          rowKey={(r) => r.id}
          columns={columns}
          dataSource={datum.filter(item => !item.delete_flag)}
        />
      </Modal>
    </>
  );
};

export default AppPermission;

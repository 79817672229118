import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NavHyperLink from "../components/NavHyperLink";
import { Tabs, TabsProps, Badge, List, Space, Pagination } from "antd";
import viewUtils from "../utils/viewUtils";
import {
  IMessage,
} from "@novu/headless";
import { connect } from "react-redux";
import {
  NotificationCategory,
  NotificationType,
} from "../interfaces/notification";
import ManageLayout from "../components/layouts/ManageLayout";
import { useHistory } from "react-router";
import useNovu from "../utils/useNovu";

const NotificationPage = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [datalist, setDatalist] = useState<{
    data?: IMessage[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState<{
    page: number;
    limit: number;
    category: NotificationCategory;
    type: NotificationType;
  }>({
    page: 0,
    limit: 10,
    category: NotificationCategory.APP,
    type: NotificationType.ALL,
  });
  const novu = useNovu();

  useEffect(() => {
    updateMessages();
  }, [filter]);

  const updateMessages = async () => {
    if (!novu.isAvailable()) {
      return;
    }

    if (history.location.pathname.includes("/app") && !props.current_app_id) {
      return;
    }
    const query: any = {
      ...filter,
      payload: filter.category === NotificationCategory.PLATFORM
      ? { category: filter.category }
      : {
          category: filter.category,
          workspace_id: props.current_workspace_id,
          app_id: props.current_app_id,
        },
    };
    if (filter.type === NotificationType.READ) {
      query.read = true;
    } else if (filter.type === NotificationType.UNREAD) {
      query.read = false;
    } else {
      query.read = undefined;
    }
    setDatalist({ data: undefined, total: 0 });
    const data = await novu.getMessages(query);
    setDatalist({
      data: data.data,
      total: data.totalCount,
    });
  };

  const handleCategoryChange = (key: string) => {
    setFilter({
      ...filter,
      page: 0,
      category: key as NotificationCategory,
    });
  };

  const handleTypeChange = (key: string) => {
    setFilter({
      ...filter,
      page: 0,
      type: key as NotificationType,
    });
  };

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, page: page });
  };

  const handleClickNotification = async (notification: IMessage) => {
    if (!novu.isAvailable()) {
      return;
    }
    const data = await novu.markMessaegsAsRead(notification._id);
    notification.read = data.data[0].read;
    setDatalist({ ...datalist });

    if (notification.payload.in_app_redirect_url) {
      let url;
      if (
        (notification.payload.in_app_redirect_url as string).startsWith("http")
      ) {
        url = `${notification.payload.in_app_redirect_url}`;
      } else {
        url = `${window.location.origin}${notification.payload.in_app_redirect_url}`;
      }
      window.open(url, "_blank")?.focus();
    }
  };

  return (
    <>
      <ManageLayout>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <NavHyperLink
            routes={[
              {
                path: "#",
                breadcrumbName: t("nav.notification"),
              },
            ]}
          />
        </div>
        <div className="page-layout-content-content pt-4">
          <div className="notification-tab">
            <Tabs
              tabPosition="left"
              renderTabBar={
                ((props, DefaultTabBar) => (
                  <DefaultTabBar {...props} className="notification-type-tab" />
                )) as TabsProps["renderTabBar"]
              }
              items={
                [
                  {
                    key: NotificationCategory.APP,
                    label: t("notification.app"),
                  },
                  {
                    key: NotificationCategory.WORKSPACE,
                    label: t("notification.workspace"),
                  },
                  {
                    key: NotificationCategory.PLATFORM,
                    label: t("notification.system"),
                  },
                ] as TabsProps["items"]
              }
              onChange={handleCategoryChange}
            />
            <div style={{ flex: 1, minWidth: "500px" }}>
              <Tabs
                defaultActiveKey="all"
                items={
                  [
                    {
                      key: NotificationType.ALL,
                      label: t("notification.all"),
                    },
                    {
                      key: NotificationType.READ,
                      label: t("notification.read"),
                    },
                    {
                      key: NotificationType.UNREAD,
                      label: t("notification.unread"),
                    },
                  ] as TabsProps["items"]
                }
                onChange={handleTypeChange}
              />
              <List
                pagination={false}
                loading={!datalist.data}
                dataSource={datalist.data}
                renderItem={(notification) => (
                  <List.Item
                    onClick={() => handleClickNotification(notification)}
                  >
                    <List.Item.Meta
                      title={
                        <Space size="small">
                          {notification.read ? null : <Badge dot></Badge>}
                          {notification.payload.in_app_title as string}
                        </Space>
                      }
                      description={
                        <div style={{ cursor: "pointer" }}>
                          {notification.payload.in_app_content as string}
                        </div>
                      }
                    />
                    <span>
                      {viewUtils.prettifyDatetime(notification.createdAt)}
                    </span>
                  </List.Item>
                )}
              />
              <Pagination
                showQuickJumper
                hideOnSinglePage={!datalist.data?.length}
                showTotal={(total) => t("common.total", { count: total })}
                current={filter.page}
                pageSize={filter.limit}
                total={datalist.total}
                onChange={handlePageChange}
                showSizeChanger={false}
                style={{ textAlign: "right", justifyContent: 'flex-end', margin: "1rem" }}
              />
            </div>
          </div>
        </div>
      </ManageLayout>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  account_info: store.account.account_info,
  current_workspace_id: store.account.current_workspace?.id,
  current_app_id: store.account.current_app_id,
});

export default connect(mapStateToProps, {})(NotificationPage);

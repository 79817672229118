import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Dropdown, Menu, Select, Space, Typography, message } from "antd";
import { cloneElement, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RunInstance } from "../interfaces/runInstance";
import AuthService from "../utils/AuthService";
import { getInstanceMembersApi, getWorkspaceUserLinksApi, updateInstanceMembersApi } from "../api/ServerApi";
import { RunInstanceMember, RunInstanceMemberRole } from "../interfaces/runInstanceMember";
import { connect } from "react-redux";
import { User } from "../interfaces/user";

interface Props {
  children: any;
  instance: RunInstance;
}

const ShareInstanceModal = (props: Props) => {
  const { t, i18n } = useTranslation();
  const currentUser = AuthService.tokenParsed();
  const [show, setShow] = useState(false);
  const [members, setMembers] = useState<RunInstanceMember[] | undefined>();
  const [users, setUsers] = useState<User[] | undefined>();
  const { run_token } = useParams();
  const isNewestMemeberRef = useRef();

  useEffect(() => {
    setMembers(undefined);
    setUsers(undefined);
    isNewestMemeberRef.current = false;
  }, [run_token]);

  useEffect(() => {
    if(!show) {
      isNewestMemeberRef.current = false;
    }
  }, [show]);

  useEffect(() => {
    if(!users) {
      getWorkspaceUserLinksApi({
        page: 1,
        page_size: 1,
        workspace_id: props.current_workspace_id,
        super_access: true,
        pagination: false
      }).then((res) => {
        if (res.status) {
          setUsers(
            Object.values(
              res.data?.reduce((ret, user) => {
                if(user.account !== props.instance?.owner) {
                  ret[user.account] = user;
                }
                return ret;
              }, {})
            )
          )
        }
      });
    }
  }, [props.current_workspace_id, props.instance, users])

  useEffect(() => {
    if (props.instance?.id && show && !isNewestMemeberRef.current) {
      isNewestMemeberRef.current = true;
      getInstanceMembersApi({
        instance_id: props.instance?.id,
      }).then((res) => {
        if (res.status) {
          setMembers(res.data)
        }
      });
    }
  }, [props.instance, show]);

  const handleAdd = (account) => {
    const user = users.find(item => {
      return item.account === account
    })
    setMembers(data => ([
      ...data,
      {
        user_workspace_link_id: user!.id,
        user_workspace_link: {
          ...user,
          workspace_id: props.current_workspace_id
        },
        run_instance_id: props.instance?.id,
        role: RunInstanceMemberRole.VISITOR
      }
    ]))
  }

  const handleChange = (member: RunInstanceMember, role: RunInstanceMemberRole) => {
    member.role = role
    setMembers([...members])
  }

  const handleDelete = (account) => {
    setMembers(members.filter(item => item?.user_workspace_link?.account !== account ))
  }

  const handleSave = () => {
    updateInstanceMembersApi({
      instance_id: props.instance?.id,
      members: members,
      locale: i18n.resolvedLanguage,
      app_id: props.current_app_id,
    }).then((res) => {
      if (res.status) {
        setShow(false)
        message.success(
          t('instance.tip.update_members', {
            status: t('common.success')
          })
        );
      } else {
        message.error(
          t('instance.tip.update_members', {
            status: t('common.fail')
          })
        );
      }
    });
  }

  return (
    <>
      <Modal
        centered
        width={550}
        title={t("instance.share_instance")}
        open={!!show}
        onCancel={() => setShow(false)}
        onOk={handleSave}
        cancelText={t('common.cancel')}
        okText={t('common.save')}
      >
        <Select
          className='hidden-select-options mb-3'
          mode="multiple"
          showSearch={true}
          optionFilterProp="children"
          style={{ width: "100%" }}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(
            (input || '').toLowerCase()
          )}
          value={ members?.map(member => member?.user_workspace_link?.account)}
          onSelect={handleAdd}
          onDeselect={handleDelete}
          options={users?.map((item) => ({
              label: `${item.name}(${item.account})`,
              value: item.account
            }))}
        />
        <div className='d-flex justify-content-between align-items-center' style={{ marginBottom: '1em' }}>
          <Typography.Text style={{ fontSize: '1.1em' }}>
            {t('instance.members')}
          </Typography.Text>
          <Typography.Text style={{ fontSize: '1.1em' }}>
            {t('common.manage')}
          </Typography.Text>
        </div>
        <div style={{ minHeight: 180 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: '1em',
              color: "rgba(0,0,0,0.65)",
              fontSize: '1em'
            }}
          >
            <Typography.Text>
              {`${currentUser?.given_name}(${props.instance?.owner
                })`}
            </Typography.Text>
            <Typography.Text disabled={true}>{t('instance.owner')}</Typography.Text>
          </div>
          {members
            ?.map((member) => (
              <div
                key={member.user_workspace_link.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: '1em',
                  color: "rgba(0,0,0,0.65)",
                  fontSize: '1em'
                }}
              >
                <Typography.Text style={{flex: 1}}>
                  {`${member?.user_workspace_link?.name}(${member?.user_workspace_link?.account})`}
                </Typography.Text>
                <Dropdown
                  dropdownRender={
                    () => <Menu
                      selectedKeys={[member.role]}
                      items={[
                        {
                          label: t('instance.can_manage'),
                          key: RunInstanceMemberRole.ADMIN,
                          onClick: () => handleChange(member, RunInstanceMemberRole.ADMIN)
                        },
                        {
                          label: t('instance.only_visit'),
                          key: RunInstanceMemberRole.VISITOR,
                          onClick: () => handleChange(member, RunInstanceMemberRole.VISITOR)
                        }
                      ]}
                    />
                  }
                  placement="bottomRight"
                >
                  <Space size={5}>
                    <Typography.Text>
                      {member.role === "ADMIN" ? t('instance.can_manage') : t('instance.only_visit')}
                    </Typography.Text>
                    <CaretDownOutlined style={{ fontSize: '0.8em', color: 'rgba(0,0,0,0.4)' }} />
                  </Space>
                </Dropdown>
                <div style={{
                  width: 1,
                  height: 10,
                  background: 'rgba(16,0,0,0.1)',
                  margin: '0 8px 0 14px'
                }} />
                <Button
                  size='small'
                  type='text'
                  onClick={() => handleDelete(member?.user_workspace_link?.account)}
                  icon={ <CloseOutlined style={{
                    fontSize: '0.9em',
                    color: 'rgba(0,0,0,0.4)'
                  }} /> }
                />
              </div>
            ))
          }
        </div>
      </Modal>
      {currentUser?.preferred_username === props.instance?.owner && cloneElement(props.children, { onClick: () => setShow(true) })}
    </>
  )
}

const mapStateToProps = (store) => ({
  current_workspace_id: store.account.current_workspace?.id,
  current_app_id: store.account.current_app_id,
});

export default connect(mapStateToProps, {})(ShareInstanceModal);
import React, {PropsWithRef} from 'react';
import { useTranslation } from 'react-i18next';

import ManageLayout from '../components/layouts/ManageLayout';
import NavHyperLink from '../components/NavHyperLink';
import TaskList from './components/TaskList';

const ExecutionPage = (props: PropsWithRef<any>) => {
    const { t } = useTranslation();

    return (
        <ManageLayout>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <NavHyperLink routes={[{
                    path: '#',
                    breadcrumbName: t('task_execution.execution_records'),
                }]} />
            </div>
            <div className='page-layout-content-content pt-4'>
                <TaskList
                    app_id={props.match.params.app_id}
                    search={true}
                    show_instance_info={true}
                />
            </div>
        </ManageLayout>
    );
};

ExecutionPage.propTypes = {

};

export default ExecutionPage;

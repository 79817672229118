import React from "react";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthService from '../../utils/AuthService';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import ManageLayout from '../../components/layouts/ManageLayout';

import { openCreateWorkspaceModal } from '../../store/account';

const ProfileComponent = (props: any) => {
  const { t } = useTranslation();
  const user = AuthService.tokenParsed();

  // const have_own_workspace = (props.workspaces || []).filter((workspace) => workspace.member_type==="OWNER").length > 0

  return (
    <ManageLayout>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1 className="h4">{t('nav.profile')}</h1>
      </div>
      <div className="bg-white">
        <div className="d-flex align-items-center profile-header py-5 text-center text-md-left">
          <div className='col-md-2' style={{padding: '2em'}}>
            <div style={{width: '100%', paddingBottom: "100%", position: 'relative'}}>
              <Avatar
                className='d-flex justify-content-center align-items-center'
                icon={<UserOutlined />}
                onError={() => true}
                style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}
              />
            </div>
          </div>
          <div>
            <h2>{user!.given_name}</h2>
            <p className="lead text-muted">{user!.preferred_username}</p>
            {/*<button className='btn btn-primary' onClick={props.openCreateWorkspaceModal} disabled={!!have_own_workspace}>*/}
            {/*  {t('workspace.new')}*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
    </ManageLayout>

  );
};

const mapStateToProps = (store) => ({
  workspaces: store.account.workspaces,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openCreateWorkspaceModal,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);

import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, message } from 'antd';
import { PlanningSession } from '../../interfaces/planningSession';
import { createSessionApi, updateSessionApi } from '../../api/ServerApi';
import { connect } from 'react-redux';

export enum SessionFormAction {Create, Update};
type Props = {
    current_workspace_id?: string;
    app_id: string;
    action?: SessionFormAction;
    successCallback?: (data: PlanningSession) => void;
    failCallback?: () => void;
    default?: PlanningSession;
    hiddenSubmit?: boolean;
    disabled?: boolean;

    refInstance?: any;
}

type SubmitData = {
    id?: string;
    name: string;
    description: string;
}

const SessionForm = (props: Props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    useImperativeHandle(props.refInstance, () => ({
        reset: (record?: PlanningSession) => {
            form.resetFields();
            if(!!record) {
                form.setFieldsValue({
                    id: record!.id,
                    name: record?.name,
                    description: record?.description
                })
            }
        },
        submit: () => {
            form.submit();
        }
    }))

    const create = async (data: SubmitData) => {
        const response = await createSessionApi({
            workspace_id: props.current_workspace_id,
            app_id: props.app_id,
            name: data.name,
            description: data.description,
            session_type: "FLOW_DIRECTION",
        })
        if (response.status) {
            message.success( t('session.tip.create', {status: t('common.success')}) );
            props!.successCallback && props!.successCallback(response.data)
        } else {
            message.error(
                `${t('session.tip.create', {status: t('common.fail')})}, ${t('workspace.no_permission')}`
            )
            props!.failCallback && props!.failCallback()
        }
    }

    const update = async (data: SubmitData) => {
        if(!data.id) {
            message.error( t('common.operation_invalid') )
            props!.failCallback && props!.failCallback()
        } else {
            const response = await updateSessionApi({
                session_id: data.id,
                name: data.name,
                description: data.description,
            })
            if (response.status) {
                message.success( t('common.update_tip', {status: t('common.success')}) )
                props!.successCallback && props!.successCallback(response.data)
            } else {
                message.error( t('common.update_tip', {status: t('common.fail')}) )
                props!.failCallback && props!.failCallback()
            }
        }
    }

    const formSubmit = async (data: SubmitData) => {
        switch(props.action)
        {
            case SessionFormAction.Create:
                await create(data);
                break;
            case SessionFormAction.Update:
                await update(data);
                break;
            default:
                message.error( t('common.operation_invalid') )
        }
    }

    return(
        <Form
            layout='vertical'
            name={`session-form`}
            form={form}
            labelAlign='left'
            initialValues={props.default ? props.default : undefined}
            onFinish={formSubmit}>
            <Form.Item
                label={t('session.name')}
                name='name'
                rules={[{ required: true, max: 30, message: '' }]}>
                <Input showCount maxLength={30} disabled={props.disabled}/>
            </Form.Item>
            <Form.Item
                label={t('session.description')}
                name='description'
                rules={[{ required: true, message: '' }]}>
                <Input.TextArea
                    showCount
                    maxLength={200}
                    autoSize={{minRows: 1}}
                    disabled={props.disabled}
                />
            </Form.Item>
            <Form.Item name='id' hidden>
                <Input />
            </Form.Item>
            {
                !props.disabled && !props.hiddenSubmit && <button className="btn btn-primary" type='submit'>
                    { t(`common.${props.action === SessionFormAction.Create ? 'create' : 'save'}`)}
                </button>
            }
        </Form>
     )
}

const mapStateToProps = (store) => ({
  current_workspace_id: store.account.current_workspace?.id,
})

let Component = connect(mapStateToProps, null)(SessionForm)
export default forwardRef((props, ref) => <Component {...props} refInstance={ref} />);
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../interfaces/user";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notification, Table, Typography, Button } from "antd";
import type { ColumnsType } from "antd/es/table";

import NavHyperLink from "../../components/NavHyperLink";
import viewUtils from "../../utils/viewUtils";
import { getMeApi, refreshSuperuserAuthTokenApi } from "../../api/ServerApi";

const AuthManage = () => {
  const [client, setClient] = useState<User | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    getMeApi().then((res) => {
      if (res.status) {
        setClient(res.data as unknown as User);
      } else {
        setClient(null);
      }
    });
  }, []);

  const handleRefreshToken = async () => {
    refreshSuperuserAuthTokenApi().then((res) => {
      if (res.status) {
        notification["success"]({
          message: t("platform_auth_manage.tips.refresh_token", {
            status: t("common.success"),
          }),
        });
        setClient(res.data as unknown as User);
      } else {
        notification["error"]({
          message: t("platform_auth_manage.tips.refresh_token", {
            status: t("common.fail"),
          }),
        });
      }
    });
  };

  const columns: ColumnsType<User> = [
    {
      title: t("platform_auth_manage.token"),
      key: "token",
      width: "25%",
      render: (p) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              title: p.token,
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
              overlayStyle: { maxWidth: "min(400px, 100vw)" },
            },
          }}
          className="mb-0"
          style={{ wordBreak: "break-all" }}
        >
          {p.token}
        </Typography.Paragraph>
      ),
    },
    {
      title: t("platform_auth_manage.expired_at"),
      key: "expired_at",
      render: (r) => viewUtils.utcToLocal(r.token_expires_at),
    },
    {
      title: t("platform_auth_manage.token_status.title"),
      key: "token_status",
      render: (r) => {
        const status = viewUtils.isExpried(r.token_expires_at);
        return (
          <Typography.Text type={status ? "success" : "danger"}>
            {t(
              `platform_auth_manage.token_status.${
                status ? "available" : "expired"
              }`
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (r) => (
        <div className="btn-group mr-2">
          <CopyToClipboard
            disabled={!r.token}
            text={r.token}
            onCopy={() => {
              notification["success"]({
                message: t("platform_auth_manage.tips.copy", {
                  status: t("common.success"),
                }),
              });
            }}
          >
            <button className="btn btn-sm btn-outline-secondary">
              {t("platform_auth_manage.action.copy")}
            </button>
          </CopyToClipboard>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <NavHyperLink
          routes={[
            {
              path: "#",
              breadcrumbName: t("system_manage.nav.auth_manage"),
            },
          ]}
        />
      </div>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Button type="primary" onClick={handleRefreshToken}>
          {t("platform_auth_manage.action.refresh_token")}
        </Button>
      </div>
      <Table
        pagination={false}
        rowKey={(r) => r.id}
        columns={columns}
        dataSource={(client ? [client] : []).filter(Boolean)}
        scroll={{ x: 700, scrollToFirstRowOnChange: true }}
      />
    </>
  );
};

export default AuthManage;

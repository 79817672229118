import React from "react";
import {ArrowLeftOutlined} from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import {Breadcrumb, Button, Typography} from "antd";
import { customHistoryGoBack } from "../utils/useCustomHistory";

interface Route {
    path: string;
    breadcrumbName: string;
}

interface Props {
    routes: Route[];
    showBack?: boolean;
    edit_modal?: any;
};

const NavHyperLink = (props: Props) => {
    const history = useHistory();

    const goBack = () => {
      customHistoryGoBack(history);
    };

    const LastName = (props.routes || [])[(props.routes?.length || 1) - 1];

    return (
      <>
        <div className='d-flex align-items-center'>
          {
              !!props.showBack && <Button
                  type='text'
                  className='common_pagetitle'
                  icon={<ArrowLeftOutlined style={{fontSize: '1.2em', color: "rgba(0,0,0,0.65)"}}/>}
                  style={{marginRight: 10}}
                  onClick={goBack}
              />
          }
          <Typography.Title
            level={4}
            className='common_pagetitle'
            style={{ maxWidth: '30vw', marginBottom: 3}}
            ellipsis={{
              rows: 1,
              tooltip: {
                placement: "bottomLeft",
                overlayClassName: 'description_tooltip',
              }
            }}>
            {LastName?.breadcrumbName || ''}
          </Typography.Title>
          {
              props.edit_modal
          }
        </div>
        {
          props.routes?.length > 1 && <Breadcrumb
            className='common_pagetitle'
            items={props.routes.map((item: Route, index: number) => ({
              title: index !== (props.routes?.length - 1) && !!item.path ? (
                <Link to={item.path}>
                  <Typography.Text style={{ maxWidth: '15vw'}} ellipsis={{
                    tooltip: {
                      placement: "bottomLeft",
                      overlayClassName: 'description_tooltip',
                    }
                  }}>
                    {item.breadcrumbName}
                  </Typography.Text>
                </Link>
              ) : (
                <Typography.Text style={{ maxWidth: '15vw'}} ellipsis={{
                  tooltip: {
                    placement: "bottomLeft",
                    overlayClassName: 'description_tooltip',
                  }
                }}>
                  {item.breadcrumbName}
                </Typography.Text>
              ),
            }))}
          />
        }
      </>
    );
}

export default NavHyperLink;

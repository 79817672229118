import React, { forwardRef, useState, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Select,
  Button,
  Form,
  Input,
  InputNumber,
} from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DataFrameFilters,
  DataFrameFilterItem,
  DataFrameField,
} from "../interfaces/chartView";

const DataFrameFilterModal = forwardRef(
  (
    props: {
      submitCallback?: (data: DataFrameFilters) => void;
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const [fields, setFields] = useState([]);
    const [showItem, setShowItem] = useState();
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      show: (filters: DataFrameFilters, fields: DataFrameField[]) => {
        setShowItem(filters);
        setFields(fields);
        form.resetFields();
        form.setFieldsValue({
          type: filters?.type || "AND",
          items: filters?.items || [],
        });
      },
      close: () => {
        handleCancel();
      },
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      let res = form.getFieldsValue();
      props.submitCallback && props.submitCallback(res);
    };

    const handleAdd = () => {
      setShowItem((data) => ({
        ...data,
        items: [...(data?.items || []), {}],
      }));
    };

    const handleDelete = (index: number) => {
      const items = (form.getFieldValue("items") || []).filter(
        (item: DataFrameFilterItem, idx: number) => idx !== index
      );
      setShowItem((data) => ({
        ...data,
        items: (data?.items || []).filter((item, idx) => idx !== index),
      }));
      form.setFieldValue("items", items);
    };

    return (
      <Modal
        width={700}
        centered
        title={t("dataframe_filter.title")}
        open={!!showItem}
        onCancel={handleCancel}
        onOk={handleSubmit}
      >
        <Form form={form} id="filter-form">
          {(showItem?.items || []).map(
            (item: DataFrameFilterItem, index: number) => (
              <div
                key={index}
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                <Form.Item
                  {...(index > 0
                    ? {
                        name: "type",
                      }
                    : {})}
                >
                  <Select
                    {...(index === 0
                      ? {
                          defaultValue: "",
                        }
                      : {})}
                    disabled={index === 0}
                    style={{ width: "6em" }}
                    options={
                      index === 0
                        ? [{ label: t("dataframe_filter.when"), value: "" }]
                        : [
                            { label: t("dataframe_filter.and"), value: "AND" },
                            { label: t("dataframe_filter.or"), value: "OR" },
                          ]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={["items", index, "field"]}
                  style={{ marginLeft: "0.5em" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "10em" }}
                    options={fields.map((field: DataFrameField) => ({
                      label: (field.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || field.name ,
                      value: field.key,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  shouldUpdate
                  style={{ marginLeft: "0.5em", marginBottom: "0px" }}
                >
                  {() => {
                    const field = fields.find(
                      (field) =>
                        field.key ===
                        (form.getFieldValue("items") || [])[index]?.field
                    );
                    if (!field) return <></>;
                    return (
                      <Form.Item
                        name={["items", index, "type"]}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "9em" }}
                          options={[
                            { label: t("dataframe_filter.equal"), value: "=" },
                            ...(field?.type === "string"
                              ? [
                                  {
                                    label: t("dataframe_filter.unequal"),
                                    value: "!=",
                                  },
                                  {
                                    label: t("dataframe_filter.include"),
                                    value: "INCLUDE",
                                  },
                                  {
                                    label: t("dataframe_filter.except"),
                                    value: "EXCEPT",
                                  },
                                  {
                                    label: t("dataframe_filter.null"),
                                    value: "NULL",
                                  },
                                  {
                                    label: t("dataframe_filter.not_null"),
                                    value: "NOT NULL",
                                  },
                                  {
                                    label: t("dataframe_filter.duplicate"),
                                    value: "DUPLICATE",
                                  },
                                ]
                              : []),
                            ...(field?.type === "number" || field?.type === "integer"
                              ? [
                                  {
                                    label: t("dataframe_filter.unequal"),
                                    value: "!=",
                                  },
                                  {
                                    label: t("dataframe_filter.greater_than"),
                                    value: ">",
                                  },
                                  {
                                    label: t(
                                      "dataframe_filter.greater_than_or_equal_to"
                                    ),
                                    value: ">=",
                                  },
                                  {
                                    label: t("dataframe_filter.less_than"),
                                    value: "<",
                                  },
                                  {
                                    label: t(
                                      "dataframe_filter.less_than_or_equal_to"
                                    ),
                                    value: "<=",
                                  },
                                  {
                                    label: t("dataframe_filter.null"),
                                    value: "NULL",
                                  },
                                ]
                              : []),
                            ...(field?.type === "boolean"
                              ? [
                                  {
                                    label: t("dataframe_filter.unequal"),
                                    value: "!=",
                                  },
                                ]
                              : []),
                          ]}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  shouldUpdate
                  style={{ flex: 1, marginLeft: "0.5em", marginBottom: "0px" }}
                >
                  {() => {
                    const field = fields.find(
                      (field) =>
                        field.key ===
                        (form.getFieldValue("items") || [])[index]?.field
                    );
                    const filterType = (form.getFieldValue("items") || [])[
                      index
                    ]?.type;
                    if (!field || !filterType) return <></>;
                    if (["NULL", "NOT NULL", "DUPLICATE"].includes(filterType))
                      return <></>;
                    if (field.type === "string") {
                      return (
                        <Form.Item
                          name={["items", index, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      );
                    }
                    if (field.type === "integer" || field.type === "number") {
                      return (
                        <Form.Item
                          name={["items", index, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                              type: "number",
                            },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      );
                    }
                    if (field.type === "boolean") {
                      return (
                        <Form.Item
                          name={["items", index, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            options={[
                              { label: t("dataframe_filter.yes"), value: true },
                              { label: t("dataframe_filter.no"), value: false },
                            ]}
                          />
                        </Form.Item>
                      );
                    }
                    return <></>;
                  }}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(index)}
                  />
                </Form.Item>
              </div>
            )
          )}
          <div>
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              style={{ padding: "10px 0 20px 0", display: 'flex', alignItems: 'center' }}
              onClick={handleAdd}
            >
              {t("dataframe_filter.add_filter")}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
);

export default DataFrameFilterModal;
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

export const customHistoryGoBack = (history) => {
  if(process.env.REACT_APP_MODE === 'simple-dev') {
    history.goBack();
  } else {
    const currentAppId = matchPath(window.location?.pathname || '', {
      path: '/app/:app_id',
      strict: true
    })
    window.customHistory.pop();
    const last = window.customHistory.slice(-1)[0];
    if(!!last) {
      history.replace(last);
    } else {
      history.replace({
        pathname: !!currentAppId ? `/app/${currentAppId?.params?.app_id}/workbench/active` : '/',
      });
    }
  }
}

const useCustomHistory = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      if(!window.customHistory) {
        window.customHistory = [];
      }
      if(window.customHistory?.length > 50) {
        window.customHistory = window.customHistory.slice(-50);
      }
      const currentUrl = `${location.pathname}${location.hash}${location.search}`;
      const last = window.customHistory.slice(-1)[0];
      if(!!last) {
        const lastUrl = `${last.pathname}${last.hash}${last.search}`;
        if(currentUrl === lastUrl) {
          window.customHistory.pop();
        }
      }
      window.customHistory.push({
        pathname: location.pathname,
        search: location.search,
        state: location.state,
        hash: location.hash,
      });
    } catch(e){
      console.log(e)
    }
  }, [location]);

  return true;
};

export default useCustomHistory;
type AMapLoaderfunc = (options: AMapLoaderOptions) => Promise<void>;
interface AMapLoaderOptions {
  key: string;
}

let config = {
  AMap: {
    version: "2.0",
  },
  Loca: {
    version: "2.0.0",
  },
};

enum LoadStatus {
  notLoad,
  loading,
  success,
  failed,
}
const STATUS = {
  AMAP: LoadStatus.notLoad,
  Loca: LoadStatus.notLoad,
};

const delay = (second: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, second)
  })
}

const loadLoca: AMapLoaderfunc = (options) =>
  new Promise(async (resolve, reject) => {
    let { key } = options;
    const parentNode = document.body || document.head;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://webapi.amap.com/loca?v=${config.Loca.version}&key=${key}`;
    script.onerror = (e) => {
      Status.Loca = LoadStatus.failed;
      reject();
    };
    script.onload = () => {
      if(!window.Loca) {
        Status.Loca = LoadStatus.failed;
        reject();
      } else {
        STATUS.Loca = LoadStatus.success;
        resolve(window.AMap);
      }
    };
    parentNode.appendChild(script);
  });

const AMapLoader: AMapLoaderfunc = (options) =>
  new Promise(async (resolve, reject) => {
    while(STATUS.AMAP === LoadStatus.loading || STATUS.Loca === LoadStatus.loading) {
      await delay(1000);
    }
    let { key } = options;
    if (!key) {
      reject('请填写key');
      return;
    }
    if (STATUS.AMAP !== LoadStatus.success) {
      STATUS.AMAP = LoadStatus.loading;
      STATUS.Loca = LoadStatus.loading;
      const parentNode = document.body || document.head;
      window.___onAPILoaded = function () {
        delete window.___onAPILoaded;
        STATUS.AMAP = LoadStatus.success;
        loadLoca(options).then(() => {
          resolve(window.AMap);
        }).catch(reject)
      };

      const script = document.createElement('script');
      script.setAttribute('id', 'gaode-map');
      script.type = 'text/javascript';
      script.src = `https://webapi.amap.com/maps?v=${config.AMap.version}&key=${key}&callback=___onAPILoaded`;
      script.onerror = (e) => {
        STATUS.AMAP = LoadStatus.failed;
        STATUS.Loca = LoadStatus.notLoad;
        reject(e);
      };
      script.onload = (e) => {
        if(!window.AMap || Object.keys(window.AMap).length < 1) {
          delete window.AMap;
          STATUS.AMAP = LoadStatus.failed;
          STATUS.Loca = LoadStatus.notLoad;
          reject("加载失败");
        }
      };
      parentNode.appendChild(script);
    } else {
      if(STATUS.Loca !== LoadStatus.success) {
        loadLoca(options).then(() => {
          resolve(window.AMap);
        }).catch(reject)
      } else {
        resolve(window.AMap);
      }
    }
  });
export default AMapLoader;
import { User } from "./user";

export interface RunInstanceMember {
    user_workspace_link_id:string;
    user_workspace_link:User;
    run_instance_id:string;
    role: RunInstanceMemberRole;
}

export enum RunInstanceMemberRole {
    ADMIN = "ADMIN",
    VISITOR = "VISITOR"
}
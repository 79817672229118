import React from 'react';

const Dashboard = () => {
    return (
        <>
            欢迎回来
        </>
    );
};

export default Dashboard;
import React from 'react';
import './Layout.css';
import { Layout, Empty, ConfigProvider } from 'antd';
import NavMenu from './NavMenu';
import Header from './Header';
import { connect } from 'react-redux';
import useCustomHistory from '../../../utils/useCustomHistory';

interface Props {
    children?: any;
    account_info?: any;
};

const SystemManageLayout = (props: Props<any>) => {
    useCustomHistory();

    if(!props.account_info?.is_superuser) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh'}}>
                <Empty description='暂无权限' />
            </div>
        )
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        headerBg: '#fff',
                        headerHeight: 60,
                        headerPadding: "0 20px 0 30px"
                    },
                },
            }}
        >
            <Layout className='system-layout'>
                <Header />
                <Layout className='d-flex'>
                    <Layout.Sider width={240} className='system-layout-slider'>
                        <NavMenu />
                    </Layout.Sider>
                    <Layout.Content className='system-layout-content'>
                        {props.children}
                    </Layout.Content>
                </Layout>
            </Layout>
        </ConfigProvider>
    );
};

const mapStateToProps = (store) => ({
  account_info: store.account.account_info,
});

export default connect(mapStateToProps, {})(SystemManageLayout);
